import { OrderInsertSuccess } from "app/@types/payment";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";

import "./index.scss";

type props = {
  orderBill: OrderInsertSuccess;
  orderDesDetail: string;
};

const CompletePayment = ({ orderBill, orderDesDetail }: props) => {
  return (
    <div className="compl-page">
      <div className="content">
        <p className="title">Đơn hàng đã nhận</p>

        <div className="thanks">Cảm ơn bạn, đơn hàng của bạn đã được nhận.</div>

        <div className="col col-des">
          <div className="wrap-des row br">
            <div className="wrap-des-header">MÃ ĐƠN HÀNG</div>
            <div className="wrap-des-detail">{orderBill.publicId}</div>
          </div>
          <div className="wrap-des row br px-1">
            <div className="wrap-des-header">NGÀY ĐẶT</div>
            <div className="wrap-des-detail">
              {moment(orderBill.dateCreate).format("DD-MM-YYYY")}
            </div>
          </div>

          <div className="wrap-des row br px-1">
            <div className="wrap-des-header">TỔNG CỘNG</div>
            <div className="wrap-des-detail">
              <NumberFormat
                value={Math.ceil(
                  orderBill.orderDetails.reduce((prev, current) => prev + current.price, 0)
                )}
                displayType={"text"}
                thousandSeparator={true}
              />
              vnđ
            </div>
          </div>
          <div className="wrap-des row px-1">
            <div className="wrap-des-header">PHƯƠNG THỨC THANH TOÁN</div>
            <div className="wrap-des-detail">Chuyển Khoản Ngân Hàng</div>
          </div>
        </div>

        <div className="row">
          <p>Nội dung chuyển khoản : Họ Tên - Số Điện Thoại - Mã đơn hàng</p>
          <p>
            Sau khi chuyển khoản bạn liên hệ mình qua facebook :{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="my-contact"
              href="https://www.facebook.com/TuiTuHoc3D/"
            >
              Tự học 3D Online
            </a>
          </p>
          <p>Hoặc số điện thoại : 0985444179 , mình sẽ gửi mã kích hoạt về email cho bạn nhé</p>

          <h2>Cảm ơn bạn đã tin tưởng và đăng ký khóa học của chúng tôi</h2>
        </div>

        <div className="row">
          <div className="wrap-detail-order col">
            <div className="wrap-detail-order-cont">
              <strong>Sản phẩm</strong>
            </div>
            <div className="wrap-detail-order-cont">
              <strong>Tổng</strong>
            </div>
          </div>

          <div className="wrap-detail-order col">
            <div className="wrap-detail-order-cont">{orderDesDetail}</div>
            <div className="wrap-detail-order-cont">
              <strong>
                <NumberFormat
                  value={Math.ceil(
                    orderBill.orderDetails.reduce((prev, current) => prev + current.price, 0)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                vnd
              </strong>
            </div>
          </div>

          <div className="wrap-detail-order col">
            <div className="wrap-detail-order-cont">
              <strong>Giao nhận hàng </strong>
            </div>
            <div className="wrap-detail-order-cont">
              <strong>Miễn phí</strong>
            </div>
          </div>

          <div className="wrap-detail-order col">
            <div className="wrap-detail-order-cont">
              <strong>Phương thức thanh toán </strong>
            </div>
            <div className="wrap-detail-order-cont">
              <strong>Chuyển khoản ngân hàng</strong>
            </div>
          </div>

          <div className="wrap-detail-order col bb">
            <div className="wrap-detail-order-cont">
              <strong>Tổng cộng</strong>
            </div>
            <div className="wrap-detail-order-cont">
              <strong>
                <NumberFormat
                  value={Math.ceil(
                    orderBill.orderDetails.reduce((prev, current) => prev + current.price, 0)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                vnd
              </strong>
            </div>
          </div>
        </div>

        <h2 className="title-pay">Thông tin chuyển khoản ngân hàng</h2>

        <div className="row">
          <div className="col my-1 col-info">
            <div className="wrap-des row">
              <div className="wrap-des-header">NGÂN HÀNG</div>
              <div className="wrap-des-detail">
                Ngân hàng Thương mại cổ phần Đầu tư và Phát triển Việt Nam (BIDV)
              </div>
            </div>
          </div>
          <div className="col my-1 col-info">
            <div className="wrap-des row">
              <div className="wrap-des-header">Tên tài khoản</div>
              <div className="wrap-des-detail">Công Ty TNHH Chicken War Studio</div>
            </div>
          </div>
          <div className="col my-1 col-info">
            <div className="wrap-des row">
              <div className="wrap-des-header">Số tài khoản</div>
              <div className="wrap-des-detail">1330201810</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletePayment;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategory } from "app/@types/category";
import categoryAPI from "app/api/services/category.service";
import { AppDispatch, RootState } from "app/store";
import { INavigationState } from "./@types";

const initialState: INavigationState = {
  categories: [],
};

export const fetchCategories = () => async (dispatch: AppDispatch) => {
  try {
    const res = await categoryAPI.getAll();
    dispatch(setCategories(res.data));
  } catch (error) {}
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setCategories(state, { payload }: PayloadAction<ICategory[]>) {
      state.categories = payload;
    },
  },
});

const { actions, reducer } = navSlice;

export const { setCategories } = actions;

export default reducer;

export const selectCategories = (state: RootState) => state.nav.categories;

import { Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import ProfileVerifyForm from "./ProfileVerifyForm";
import "./index.scss";
import CoursePreview from "./CoursePreview";
import CompletePayment from "app/common/components/completepayment";
import { OrderInsertSuccess } from "app/@types/payment";
import styled from "styled-components";

const ProfileVerify = () => {
  const [OrderBill, setOrderBill] = useState<OrderInsertSuccess | undefined>();

  const [orderDesDetail, setOrderDesDetail] = useState("");

  if (OrderBill) return <CompletePayment orderBill={OrderBill} orderDesDetail={orderDesDetail} />;

  return (
    <PVWrapper className="row prof-ver">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} sm={12} lg={8}>
          <Paper elevation={0} className="p-2">
            <ProfileVerifyForm setOrderBill={setOrderBill} setOrderDesDetail={setOrderDesDetail} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} sm={12} lg={4}>
          <Paper elevation={0} className="p-1">
            <CoursePreview />
          </Paper>
        </Grid>
      </Grid>
    </PVWrapper>
  );
};

export default ProfileVerify;

const PVWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.responsive.desktopSmall}) {
    .MuiGrid-spacing-xs-2 {
      flex-direction: column-reverse !important;
    }
  }
`;

import React from "react";
import zalo from "app/assets/zalo.png";
import gg from "app/assets/gg.png";
import face from "app/assets/face.png";
import styled from "styled-components";
import { logoFooter } from "app/constants/images";
import { Link } from "react-router-dom";

const FooterWrapper = styled.div`
  background: #2c3241;
  padding: 0px 5%;
  margin-top: 2rem !important;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-bottom: 12%;

  * {
    color: white !important;
  }

  @media (max-width: ${({ theme }) => theme.responsive.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.responsive.mobileLarge}) {
    grid-template-columns: repeat(1, 1fr);
  }

  .link {
    font-size: 16px;
    color: ${({ theme }) => theme.mainColor} !important;
  }

  h3 {
    font-weight: 600 !important;
    margin-bottom: 5px;
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: #3d3217;
  }

  .intro {
    padding-top: 10%;

    a {
      margin-top: 1.5%;
      color: #3c2c27;
      font-weight: normal !important;
    }
  }

  .contact {
    padding-top: 10%;

    .options {
      .wrap-contact {
        background-color: white;
        border-radius: 8px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        padding: 0.35rem;
        margin-right: 1rem;
        a {
          margin: 0 !important;
          padding: 0 !important;
        }
        img {
          width: 40px;
          height: 40px;
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
    .success {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 70%;
        border-radius: 50%;
      }
    }
  }

  .wrap-logo {
    img {
      height: 200px;
      margin-top: 40px;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.mobileLarge}) {
    .wrap-logo {
      display: flex;
      justify-content: center;
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="wrap-logo">
        <img src={logoFooter} alt="logo" />
      </div>

      <div className="intro row contact">
        <Link className="link" to="/hotro/hoadon">
          Hướng dẫn thanh toán hóa đơn các khóa học
        </Link>
        <Link className="link" to="/hotro/dangky">
          Hướng dẫn đăng ký tài khoản
        </Link>
      </div>

      <div className="intro contact row">
        <h3>Liên hệ với chúng tôi tại</h3>
        <div className="wrap-contact">
          <div className="options col">
            <div className="wrap-contact">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/TuiTuHoc3D/"
              >
                <img src={face} alt="" />
              </a>
            </div>

            <div className="wrap-contact">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=tuhoc3donline@gmail.com&su=&body="
              >
                <img src={gg} alt="" />
              </a>
            </div>

            <div className="wrap-contact">
              <a target="_blank" rel="noopener noreferrer" href="https://zalo.me/0985444179">
                <img src={zalo} alt="" />
              </a>
            </div>
          </div>
        </div>
        <p className="mt-1">
          Số điện thoại : <strong>0985444179</strong>
        </p>
        <p className="mt-1">
          Email liên hệ : <strong>tuhoc3donline@gmail.com</strong>
        </p>
      </div>
    </FooterWrapper>
  );
};

export default React.memo(Footer);

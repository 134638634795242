import PasswordInput from "app/common/form/passwordinput";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./styles.scss";
import { Alert } from "@material-ui/lab";
import { useAppSelector } from "app/store/hooks";
import { selectUserProfile } from "features/account/userProfileSlice";
import userAPI from "app/api/services/user.service";
import { showError, showSuccess } from "app/notification";
import styled from "styled-components";

const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Mật khẩu cũ bắt buộc")
    .min(8, "Mật khẩu ít nhất 8 kí tự")
    .max(20, "Mật khẩu tối đa 20 kí tự")
    .matches(/[A-Z]+/, "Phải có chữ in hoa")
    .matches(/[0-9]+/, "Phải có chữ số 0 đến 9")
    .matches(/[a-z]+/, "Phải có chữ thường")
    .matches(/[-[/\]{}()@*+?.,\\^$|#\s]/g, "Phải có ký tự đặt biệt"),
  password1: yup
    .string()
    .required("Mật khẩu bắt buộc")
    .min(8, "Mật khẩu ít nhất 8 kí tự")
    .max(20, "Mật khẩu tối đa 20 kí tự")
    .matches(/[A-Z]+/, "Phải có chữ in hoa")
    .matches(/[0-9]+/, "Phải có chữ số 0 đến 9")
    .matches(/[a-z]+/, "Phải có chữ thường")
    .matches(/[-[/\]{}()@*+?.,\\^$|#\s]/g, "Phải có ký tự đặt biệt"),
  password2: yup
    .string()
    .required("Nhập lại mật khẩu ")
    .min(8, "Mật khẩu ít nhất 8 kí tự")
    .max(20, "Mật khẩu tối đa 20 kí tự")
    .matches(/[A-Z]+/, "Phải có chữ in hoa")
    .matches(/[0-9]+/, "Phải có chữ số 0 đến 9")
    .matches(/[a-z]+/, "Phải có chữ thường")
    .matches(/[-[/\]{}()@*+?.,\\^$|#\s]/g, "Phải có ký tự đặt biệt"),
});

const ChangePassword = () => {
  const [messageError, setMessageError] = useState("");

  const email = useAppSelector(selectUserProfile)?.email;

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  const onSubmit = (data: any) => {
    if (data.password1 !== data.password2) {
      setMessageError("Mật khẩu mới không giống nhau nhau");
      return;
    }
    if (data.oldPassword === data.password1) {
      setMessageError("Mật khẩu cũ không được trùng mật khẩu mới");
      return;
    }
    userAPI
      .updatePassword({
        email: email + "",
        oldPassword: data.oldPassword,
        newPassword: data.password1,
      })
      .then((res) => {
        showSuccess("Cập nhật mật khẩu thành công");
      })
      .catch((err) => {
        showError(err.error);
      })
      .finally(() => {
        setMessageError("");
      });
  };

  return (
    <ChangePassWrapper className="change-pass container-wrap">
      <h2>Thay đổi mật khẩu</h2>
      <div className="row">
        {messageError && <Alert severity="error">{messageError}</Alert>}
        <form onSubmit={handleSubmit(onSubmit)} className="mt-2">
          <PasswordInput
            classes="w-50"
            label="Mật khẩu cũ"
            control={control}
            errors={errors}
            name="oldPassword"
          />
          <PasswordInput
            classes="mt-2 w-50"
            label="Mật khẩu mới"
            control={control}
            errors={errors}
            name="password1"
          />
          <PasswordInput
            classes="mt-2 w-50"
            label="Nhập lại mật khẩu mới"
            control={control}
            errors={errors}
            name="password2"
          />
          <div className="mt-2 w-50 d-f center">
            <button className="btn-change-pass">Cập nhật mật khẩu</button>
          </div>
        </form>
      </div>
    </ChangePassWrapper>
  );
};

export default ChangePassword;

const ChangePassWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.responsive.desktopSmall}) {
    .w-50 {
      width: 100% !important;
    }
  }
`;

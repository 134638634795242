import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "app/common/form/textinput";
import PasswordInput from "app/common/form/passwordinput";
import { history } from "index";
import { useAppDispatch } from "app/store/hooks";
import { userLogin } from "features/account/userProfileSlice";
import styled from "styled-components";
import SendEmailResetPassword from "../send-email-reset-pw";

const SignupSchema = yup.object().shape({
  email: yup.string().required("Tên đăng nhập bắt buộc").email("Email sai định dạng"),
  password: yup
    .string()
    .required("Mật khẩu bắt buộc")
    .min(8, "Mật khẩu ít nhất 8 kí tự")
    .max(20, "Mật khẩu tối đa 20 kí tự")
    .matches(/[A-Z]+/, "Phải có chữ in hoa")
    .matches(/[0-9]+/, "Phải có chữ số 0 đến 9")
    .matches(/[a-z]+/, "Phải có chữ thường")
    .matches(/[-[/\]{}()@*+?.,\\^$|#\s]/g, "Phải có ký tự đặt biệt"),
});

const LoginFormWrap = styled.div`
  padding: 13px;
  width: 90%;

  @media (max-width: ${({ theme }) => theme.responsive.mobileLarge}) {
    width: 100% !important;
  }

  .orlg {
    margin-top: 15px;
    font-size: 15px !important;
    color: #a5a5a5;
  }

  .f-pass {
    margin-top: 20px;
    font-size: 14px;
    cursor: pointer;
  }

  .form-login {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;

    padding: 23px;

    h2 {
      font-weight: bold;
    }

    .head {
      &.col {
        @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
          flex-direction: column !important;
          align-items: flex-start;
          .ml-auto {
            margin-left: 0 !important;
          }
        }
      }
      .wes {
        font-size: 14px;
        color: #495057;
        margin-right: 3px;
      }
      .regis {
        color: #ea3939;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .btn-login {
      padding: 7px 0px;
      background-color: #ffc000;
      color: white;
      border-radius: 24px;
      font-size: 1.3rem;
      border: 0px !important;
      cursor: pointer;
    }

    .wrap-lg {
      margin-top: 15px;
      margin-bottom: 15px;
      &.col {
        @media (max-width: ${({ theme }) => theme.responsive.mobileLarge}) {
          flex-direction: column !important;

          .wrap-lg-ano {
            width: 100% !important;
          }

          .gg {
            padding: 0 !important;
            .wrap-logo {
              img {
                padding: 4px !important;
                margin: 3px;
              }
            }
          }
        }
      }
    }

    .fb {
      border-radius: 5px;
      background-color: #1178f2;
      color: white;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .gg {
      border-radius: 5px;
      padding: 5px;
      background-color: rgb(223, 74, 50);
      color: white;
      img {
        width: 25px;
        background-color: white;
        border-radius: 50%;
        padding: 8px;
      }
    }

    .wrap-lg-ano {
      font-size: 1.2rem;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const LoginForm = () => {
  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const dispatch = useAppDispatch();

  const [forgetPW, setForgetPW] = useState(false);

  const onSubmit = (data: any) => {
    dispatch(userLogin(data));
  };

  return (
    <LoginFormWrap className="d-f center">
      <div className="form-login row">
        {!forgetPW ? (
          <>
            <div className="head col center">
              <h2>Đăng Nhập</h2>

              <div className="wes ml-auto">Bạn chưa có tài khoản ?</div>
              <div
                className="regis"
                onClick={(e) => {
                  history.push("/taikhoan/dangky");
                }}
              >
                Đăng ký
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                classes="mt-2 w-100"
                label="Email"
                control={control}
                errors={errors}
                name="email"
              />
              <PasswordInput
                classes="mt-2 w-100"
                label="Mật khẩu"
                control={control}
                errors={errors}
                name="password"
              />
              <button className="btn-login mt-2 w-100 d-f center" type="submit">
                Đăng Nhập
              </button>
            </form>
            <div className="d-f center w-100 f-pass" onClick={() => setForgetPW(true)}>
              Quên mật khẩu ?
            </div>
          </>
        ) : (
          <SendEmailResetPassword setForgetPW={setForgetPW} />
        )}
      </div>
    </LoginFormWrap>
  );
};

export default LoginForm;

import { Grid, Paper } from "@material-ui/core";
import React from "react";
import CartDetail from "./CartDetail";
import CartDetailPrice from "./CartDetailPrice";
import "./index.scss";

const CartView = () => {
  return (
    <div className="row cart-page mt-1">
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} sm={12} lg={8}>
          <Paper elevation={0} className="p-2 p-d">
            <CartDetail />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} sm={12} lg={4}>
          <Paper elevation={0} className="p-1 p-d">
            <CartDetailPrice />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CartView;

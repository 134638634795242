import React from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import DetailCourse from "./pages/detail/DetailCourse";
import Searching from "./pages/search";
import CourseStudy from "./pages/study";
import Topic from "./pages/topic";

const Course: React.FC<RouteComponentProps> = ({ location, match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/timkiem`} component={Searching} />
      <Route path={`${match.url}/xemkhoahoc/:courseId`} component={CourseStudy} />
      <Route
        path={`${match.url}/chude/:name`}
        key={location.key}
        component={Topic}
      />
      <Route path={`${match.url}/:courseId`} component={DetailCourse} />
    </Switch>
  );
};

export default withRouter(Course);

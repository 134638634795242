import React from "react";
import styled from "styled-components";

interface ImageRowProps {
  src: string;
  des?: any;
}

const ImageRow = ({ src, des }: ImageRowProps) => {
  return (
    <ImageRowStyles>
      <img alt="irow" src={src}></img>
      <p>{des}</p>
    </ImageRowStyles>
  );
};

const ImageRowStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  img {
    max-width: 80%
  }
`;

export default ImageRow;

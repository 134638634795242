import React, { useState } from "react";
import "./styles.scss";
import { AiFillPlayCircle, AiFillEye } from "react-icons/ai";
import { ILecture } from "app/@types/course";
import courseAPI from "app/api/services/course.service";
import { useAppSelector } from "app/store/hooks";
import { selectUserId } from "features/account/userProfileSlice";
import { Tag, Modal } from "antd";
import ReactPlayer from "react-player";

interface IProps {
  data: ILecture;
  isPub?: boolean;
  isSeen?: boolean;
  setSelectLecture?: (data: ILecture) => void;
  isFrist?: boolean;
  publicVideoDemo?: string;
}

const CardLecture: React.FC<IProps> = ({
  data,
  isPub = false,
  setSelectLecture,
  isSeen,
  isFrist = false,
  publicVideoDemo,
}) => {
  const userId = useAppSelector(selectUserId);

  const [currentSeen, setcurrentSeen] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClick = () => {
    if (setSelectLecture) {
      setSelectLecture(data);
      if (userId)
        courseAPI
          .setSeenLecture({
            userId,
            lectureId: data.id,
          })
          .then(() => {
            setcurrentSeen(true);
          });
    }
  };

  const handleDemo = () => {
    showModal();
  };

  return (
    <div className="card-lecture col" onClick={handleClick}>
      <div
        className={
          data.isSeen || currentSeen ? "wrap-icon center wrap-icon-active" : "wrap-icon center"
        }
      >
        <AiFillPlayCircle />
      </div>
      <div className="content d-f center">{data.name}</div>
      <div className="des col">
        <div className="review">{isPub && <AiFillEye size={70} />}</div>
        {isSeen && (
          <div className="d-f center">
            <Tag color="#87d068">Đang xem</Tag>
          </div>
        )}
        {isFrist && (
          <div className="d-f center">
            <Tag color="#87d068" onClick={handleDemo}>
              Xem Thử
            </Tag>
            {isModalVisible && (
              <Modal
                title={null}
                footer={null}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
                className="modal-custom-preview"
              >
                <ReactPlayer
                  config={{ file: { attributes: { controlsList: "nodownload" } } }}
                  onContextMenu={(e: any) => e.preventDefault()}
                  controls
                  url={publicVideoDemo}
                  playing={true}
                />
              </Modal>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardLecture;

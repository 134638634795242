import { OrderHistoryUnpay } from "app/@types/payment";
import paymentAPI from "app/api/services/order.service";
import OrderCard from "app/common/components/ordercard";
import { useAppSelector } from "app/store/hooks";
import { selectUserId } from "features/account/userProfileSlice";
import React, { useEffect, useState } from "react";
import "./styles.scss";

const OrderHsitory = () => {
  const userId = useAppSelector(selectUserId);
  const [listOrderHistory, setListOrderHistory] = useState<
    OrderHistoryUnpay[] | undefined
  >();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (userId)
      paymentAPI
        .orderHistoryHaspay(userId)
        .then((res) => {
          setListOrderHistory(res.data);
        })
        .catch(() => {
          setError(true);
        });
  }, []);

  return (
    <div className="order-his row">
      <h3 className="head" style={{ marginBottom: "10px" }}>
        Danh sách hóa đơn đã thanh toán
      </h3>
      {!error && !listOrderHistory && <OrderCard />}
      {listOrderHistory &&
        listOrderHistory.map((value, index) => (
          <OrderCard isSale key={index} order={value} />
        ))}
    </div>
  );
};

export default OrderHsitory;

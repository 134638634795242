import { ICourseSearch } from "app/@types/course";
import CardCourseHori from "app/common/components/cardcoursehori";
import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  data: ICourseSearch[];
  isLoading: boolean;
}

const Container10Running = () => {
  let arr: any = [];
  for (let index = 0; index < 10; index++) {
    arr.push(<CardCourseHori />);
  }
  return arr;
};

const Container10CourseVer: React.FC<IProps> = ({ data, isLoading }) => {
  return (
    <div className="row w-100 mt-2">
      {!isLoading
        ? data.map((val) => (
            <Link to={`/khoahoc/${val.id}`}>
              <CardCourseHori data={val} />
            </Link>
          ))
        : Container10Running()}
    </div>
  );
};

export default React.memo(Container10CourseVer);

import React from "react";
import "./styles.scss";
import { BsFillStarFill } from "react-icons/bs";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import loadingCss from "styles/scss/loading.styled";
import { history } from "index";
interface IProps {
  className?: string;
  image?: string;
  title?: string;
  author?: string;
  price?: number;
  percent?: number;
  loading?: boolean;
  description?: string | null;
  to?: string;
  isNew?: boolean;
}

const CardCourseLoading = styled.div`
  min-height: 230px;
  height: 230px;
  border-radius: 5px;
  background-color: white;

  .load-img {
    height: 45%;
    background-color: #e4e4e4;
    margin: 10px;
    ${loadingCss}
  }

  .load-rating {
    height: 5%;
    margin-left: 10px;
    width: 45%;
    background-color: #e4e4e4;
    ${loadingCss}
  }

  .load-auth {
    height: 5%;
    margin-left: 10px;
    width: 50%;
    background-color: #e4e4e4;
    ${loadingCss}
  }

  .load-title {
    height: 15%;
    margin: 10px;
    background-color: #e4e4e4;
    ${loadingCss}
  }

  .load-price {
    height: 10%;
    margin: 5px 10px;
    width: 45%;
    background-color: #e4e4e4;
    ${loadingCss}
  }
`;

const CardCourse: React.FC<IProps> = ({
  className,
  image,
  title,
  author,
  price,
  loading,
  description,
  to,
  isNew,
  percent,
}) => {
  if (loading)
    return (
      <CardCourseLoading className="row">
        <div className="load-img"></div>
        <div className="load-rating"></div>
        <div className="load-title"></div>
        <div className="load-auth"></div>
        <div className="load-price"></div>
      </CardCourseLoading>
    );

  const handleClick = () => {
    if (to) {
      history.push(to);
    }
  };

  const generatePrice = () => {
    if (isNaN(percent as any) || percent === 0) {
      return (
        <h4>
          <NumberFormat
            value={Math.ceil(price || 0)}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          đ
        </h4>
      );
    } else {
      return (
        <div className="d-flex d-row">
          <h4 className="old-price">
            <NumberFormat
              value={Math.ceil(price || 0)}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            đ
          </h4>
          <h4>
            <NumberFormat
              value={Math.ceil((price as any) * (1 - (percent as any)))}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            đ
          </h4>
        </div>
      );
    }
  };

  return (
    <div className={className ? "course row" + className : "course row"} onClick={handleClick}>
      {isNew && <div className="new">Hot</div>}
      {image && (
        <div className="img-wrapper">
          <img src={image} alt="crs" />
        </div>
      )}

      <div className="wrap-rating col">
        <div className="rating col">
          <BsFillStarFill />
          <BsFillStarFill />
          <BsFillStarFill />
          <BsFillStarFill />
          <BsFillStarFill />
        </div>
        <div className="total-student">7600</div>
      </div>

      <div className="wrap-content">
        <p>
          {description &&
            (description.length <= 28 ? description : description.substring(0, 28) + " ...")}
        </p>
        <h3>{title}</h3>
        {author && <p>Tác giả : {author}</p>}
        {generatePrice()}
      </div>
    </div>
  );
};

export default CardCourse;

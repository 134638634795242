export const convertprice = (price?: number): string => {
  if (!price) return "0";

  return "";
};

export const createConditionList = (condition: string | null | undefined) => {
  if (!condition) return [];
  if (!condition.includes(",")) return [condition];

  return condition.split(",");
};

export const convertDataFormat = (data?: string) => {
  if (!data) return "";
  let out = "";
  let arr = data.split("T")[0].split("-");
  arr.reverse().forEach((value) => {
    out += `${value}/`;
  });
  return out.substr(0, out.length - 1);
};

import { Grid } from "@material-ui/core";
import { ICourse } from "app/@types/course";
import { useAppDispatch } from "app/store/hooks";
import { removeCourseFromCart } from "features/cart/cartSlice";
import React from "react";
import { TiDelete } from "react-icons/ti";
import NumberFormat from "react-number-format";

interface IProps {
  data: ICourse;
  index: number;
}

const CartDetailItem: React.FC<IProps> = ({ data, index }) => {
  const dispatch = useAppDispatch();

  const hanleDelete = () => {
    dispatch(removeCourseFromCart(index));
  };

  return (
    <Grid container spacing={2} className="top-line">
      <Grid item lg={3} xs={12}>
        <div className="wrap-img d-f center">
          <img src={data.photo || ""} alt="img" />
        </div>
      </Grid>
      <Grid item lg={6} xs={6} className="title">
        {data.name}
      </Grid>
      <Grid item lg={2} xs={4} className="price">
        <NumberFormat
          value={Math.ceil(data.price * (1 - data.percent))}
          displayType={"text"}
          thousandSeparator={true}
        />
        đ
      </Grid>
      <Grid item lg={1} xs={2}>
        <TiDelete onClick={(e) => hanleDelete()} />
      </Grid>
    </Grid>
  );
};

export default CartDetailItem;

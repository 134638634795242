import React, { useEffect, useState } from "react";
import { ResetPasswordStyles } from "./styles";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PasswordInput from "app/common/form/passwordinput";
import { Spin } from "antd";
import { Alert } from "@material-ui/lab";
import { history } from "index";
import { ResetPassByCode } from "app/model/requests/account";
import userAPI from "app/api/services/user.service";
import { showError, showSuccess } from "app/notification";

const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Mật khẩu bắt buộc")
    .min(8, "Mật khẩu ít nhất 8 kí tự")
    .max(20, "Mật khẩu tối đa 20 kí tự")
    .matches(/[A-Z]+/, "Phải có chữ in hoa")
    .matches(/[0-9]+/, "Phải có chữ số 0 đến 9")
    .matches(/[a-z]+/, "Phải có chữ thường")
    .matches(/[-[/\]{}()@*+?.,\\^$|#\s]/g, "Phải có ký tự đặt biệt"),
  password1: yup
    .string()
    .required("Mật khẩu bắt buộc")
    .min(8, "Mật khẩu ít nhất 8 kí tự")
    .max(20, "Mật khẩu tối đa 20 kí tự")
    .matches(/[A-Z]+/, "Phải có chữ in hoa")
    .matches(/[0-9]+/, "Phải có chữ số 0 đến 9")
    .matches(/[a-z]+/, "Phải có chữ thường")
    .matches(/[-[/\]{}()@*+?.,\\^$|#\s]/g, "Phải có ký tự đặt biệt"),
});

interface Props {}

const ResetPassword = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [messageError, setMessageError] = useState("");
  const [paramsUpdate, setParamsUpdate] = useState<ResetPassByCode>(new ResetPassByCode());
  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const email = query.get("email");
    const code = query.get("key");
    if (!code || !email) {
      history.push("/khongtimthay");
    }
    const queryObj = new ResetPassByCode({
      email,
      code: code?.replaceAll(" ", "+"),
    } as any);

    userAPI
      .updatePasswordByKeycode(queryObj)
      .then(() => {
        setParamsUpdate(queryObj);
      })
      .catch((err) => {
        setMessageError(err.error ? err.error : "Server Error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = (data: any) => {
    if (data.password1 !== data.password) {
      showError("Mật khẩu mới không giống nhau nhau");
      return;
    }

    const params = { ...paramsUpdate };
    params.password = data.password;
    params.isVerify = false;
    setLoading(true);
    userAPI
      .updatePasswordByKeycode(params)
      .then(() => {
        showSuccess(
          "Chúc mừng bạn đã thay đổi mật khẩu thành công - Vui lòng đăng nhập với mật khẩu mới",
          10000
        );
        history.push("/taikhoan/dangnhap");
      })
      .catch((err) => {
        showError(err.error ? err.error : "Server Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ResetPasswordStyles>
      {loading && (
        <div className="loading">
          <Spin />
        </div>
      )}
      {messageError ? (
        <Alert severity="error">{messageError}</Alert>
      ) : (
        <>
          <h1>Nhập mật khẩu mới</h1>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100 mt-2">
            <PasswordInput
              classes="w-100"
              label="Mật khẩu mới"
              control={control}
              errors={errors}
              name="password"
            />
            <PasswordInput
              classes="w-100 mt-2"
              label="Nhập lại mật khẩu mới"
              control={control}
              errors={errors}
              name="password1"
            />
            <div className="mt-2">
              <button type="submit" className="btn-reset-pass">
                Cập nhật mật khẩu
              </button>
            </div>
          </form>
        </>
      )}
    </ResetPasswordStyles>
  );
};

export default ResetPassword;

import {
  IAccountLogin,
  IAccountRegister,
  IAccountUpdatePassword,
} from "app/@types/account";
import { ResetPassByCode } from "app/model/requests/account";
import { IGetResponse, IResponseWithData } from "../responses/base";
import { IProfile, IProfileUser } from "../responses/user";
import requests from "./base.service";

const AUTH_URL = process.env.REACT_APP_AUTH_URL as string;
const endPoint = `${AUTH_URL}user`;

const userAPI = {
  registerAccount: (
    data: IAccountRegister
  ): Promise<IResponseWithData<IProfile>> =>
    requests.post(`${endPoint}/register`, { account: data }),

  getCurrent: (): Promise<IGetResponse<IProfileUser>> =>
    requests.get(`${endPoint}/current`),

  login: (data: IAccountLogin): Promise<IResponseWithData<IProfile>> =>
    requests.post(`${endPoint}/login`, { account: data }),

  updateProfile: (data: IAccountRegister) =>
    requests.put(`${endPoint}`, { account: data }),

  updatePassword: (data: IAccountUpdatePassword) =>
    requests.put(`${endPoint}/password`, { account: data }),

  updatePasswordByKeycode: (data: ResetPassByCode) =>
    requests.post(`${endPoint}/updatePwByCode`, data),

  sendEmailResetPW: (email: string) =>
    requests.get(`${endPoint}/sendMailUpdatePW?email=${email}`),
};

export default userAPI;

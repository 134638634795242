import React from "react";

interface IProps {
  height?: number | string;
  width?: number | string;
  radius?: string;
}

const LoadFb: React.FC<IProps> = ({
  height = 20,
  width = "100%",
  radius = "3px",
}) => {
  const styles = { height, width, borderRadius: radius };

  return <div className="load-fb" style={styles}></div>;
};

export default LoadFb;

import PrivateRoute from "app/routers/PrivateRoute";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Main from "./pages/main";
import Profile from "./pages/profile";

const Account = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute path={`${match.url}/trangcanhan`} component={Profile} />
      <Route path={`${match.url}`} component={Main} />
    </Switch>
  );
};

export default Account;

import React from "react";
import "./index.scss";
import link from "app/assets/Subscribe.png";

interface IProps {
  data: {
    content: string;
    courseName: string;
    img?: string;
    createdAt: string;
    isSeen: boolean;
  };
}

const NotificateItem: React.FC<IProps> = ({ data }) => {
  const { content, courseName, img, createdAt, isSeen } = data;

  return (
    <div className={`col noti-item ${isSeen ? "blg" : "blr"}`}>
      <div className="wrap-img">
        <img src={img || link} alt="im" />
      </div>
      <div className="content">
        <div className="h3">
          <strong>Nội Dung : </strong> {content}
        </div>
        <div className="h3">
          <strong>Khóa học : </strong> {courseName}
        </div>
        <div className="h3">
          <strong>Ngày tạo : </strong> {createdAt}
        </div>
      </div>
    </div>
  );
};

export default NotificateItem;

import NotFound from "app/layout/NotFound";
import Account from "features/account";
import Course from "features/course";
import Home from "features/home";
import Cart from "features/cart";
import ProfileVerify from "features/cart/pages/ProfileVerifyForm/ProfileVerify";
import Support from "features/support";
import Blog from 'features/blog'
interface IRouterMap {
  path: string;
  component: any;
  exact: boolean;
  isPrivate: boolean
}

export const routersMap: IRouterMap[] = [
  {
    path: "/",
    component: Home,
    exact: true,
    isPrivate: false
  },
  {
    path: "/khoahoc",
    component: Course,
    exact: false,
    isPrivate: false
  },
  {
    path: "/taikhoan",
    component: Account,
    exact: false,
    isPrivate: false
  },
  {
    path: "/giohang",
    component: Cart,
    exact: false,
    isPrivate: false
  },
  {
    path: "/xacnhanthanhtoan",
    component: ProfileVerify,
    exact: false,
    isPrivate: false
  },
  {
    path: "/hotro",
    component: Support,
    exact: false,
    isPrivate: false
  },
  {
    path: "/blog",
    component: Blog,
    exact: false,
    isPrivate: false
  },
  {
    path: "/khongtimthay",
    component: NotFound,
    exact: true,
    isPrivate: false
  },
];

import React from "react";
import CardBenefit from "../cardben";
import CardDescriptionCourse from "../carddes";
import CardRequire from "../cardrequire";
import CardTitle from "../cardtitle";
import CardVideos from "../cardvideos";
import Feedback from "../feedback";
import "./styles.scss";

const CardContent = () => {
  return (
    <div className="card-ct row">
      <div className="card-frame">
        <CardTitle />
        <CardBenefit />
        <CardDescriptionCourse />
      </div>

      <div className="card-frame">
        <CardRequire isConditionRequirement={true} title="Yêu cầu khóa học" />
      </div>

      <div className="card-frame">
        <CardRequire
          isConditionRequirement={false}
          title="Khóa học này phù hợp với"
        />
      </div>

      <div className="card-frame">
        <CardVideos />
      </div>

      <div className="card-frame">
        <Feedback />
      </div>
      {/* <div className="card-frame">
        <Comments />
      </div> */}
    </div>
  );
};

export default CardContent;

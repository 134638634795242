import { useAppSelector } from "app/store/hooks";
import { selectCarts, selectTotalPrice } from "features/cart/cartSlice";
import React from "react";
import NumberFormat from "react-number-format";
import CourseDetailPreview from "./CourseDetailPreview";

const CoursePreview = () => {
  const totalPrice = useAppSelector(selectTotalPrice);

  const carts = useAppSelector(selectCarts);

  return (
    <div className="row cour-preview">
      <h3 className="p-1">Đơn hàng của bạn</h3>
      <div className="p-1 wrap-list">
        {carts.map((item, id) => (
          <CourseDetailPreview
            name={item.name || ""}
            key={id}
            price={Math.ceil(item.price * (1 - item.percent))}
          />
        ))}
      </div>
      <div className="col wrap-price p-1">
        <p>Tổng Tiền</p>
        <p className="final-price">
          <NumberFormat value={totalPrice} displayType={"text"} thousandSeparator={true} /> đ
        </p>
      </div>
    </div>
  );
};

export default CoursePreview;

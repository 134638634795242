import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RadioInput from "app/common/form/radioinput";
import TextInput from "app/common/form/textinput";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { selectUserProfile, userUpdateProfile } from "features/account/userProfileSlice";
import styled from "styled-components";

const CustomerInfoSchema = yup.object().shape({
  displayName: yup.string().required("Tên bắt buộc"),
  email: yup.string().required("Email bắt buộc"),
  gender: yup.string().default("true"),
  phoneNumber: yup.string().required("Số điện thoại là bắt buộc"),
});

const ProfileCustomer = () => {
  const user = useAppSelector(selectUserProfile);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(CustomerInfoSchema),
    defaultValues: {
      displayName: user?.username,
      email: user?.email,
      gender: user?.gender + "",
      phoneNumber: user?.phoneNumber,
    },
  });

  const dispatch = useAppDispatch();

  const onSubmit = (data: any) => {
    data.gender = data.gender === "true";
    dispatch(userUpdateProfile(data));
  };
  return (
    <ProfileWapper className="row container-wrap">
      <h2>Thông tin tài khoản</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col w-100">
          <div className="row w-50">
            <TextInput disabled label="Email" name="email" control={control} errors={errors} />
            <TextInput label="Số điện thoại" name="phoneNumber" control={control} errors={errors} />
          </div>
          <div className="row w-50">
            <TextInput label="Họ và tên" name="displayName" control={control} errors={errors} />

            <div className="col foot">
              <div className="w-50">
                <RadioInput
                  defaultValue="true"
                  label="Giới tính"
                  control={control}
                  name="gender"
                  options={[
                    { label: "Nam", value: "true" },
                    { label: "Nữ", value: "false" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="mt-1">
          Cập nhật
        </button>
      </form>
    </ProfileWapper>
  );
};

export default ProfileCustomer;

const ProfileWapper = styled.div`

  .col {
    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      flex-direction: column !important;
    }
  }

  .w-50 {
    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      width: 100% !important ;
    }
  }

  .MuiTextField-root {
    width: 90%;
    margin-bottom: 15px;
    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      width: 100% !important ;
    }
  }

  button {
    cursor: pointer;
    transition: all 0.3s ease 0s;
    padding: 8px 12px;
    background-color: #ffbe00;
    color: white;
    font-size: 1.1rem !important;
  }

  button:hover {
    background-color: #f09819;
  }
`;

import { ICourseNotification } from "app/@types/course";
import notificateAPI from "app/api/services/notification.service";
import NotificateItem from "app/common/components/notificate";
import { useAppSelector } from "app/store/hooks";
import { selectUserId } from "features/account/userProfileSlice";
import React, { useEffect, useState } from "react";
import "./index.scss";

const convert2View = (data: ICourseNotification[]) => {
  let dataConv: any[] = [];

  data.map((value) => {
    value.notifications.map((noti) => {
      dataConv.push({
        content: noti.content,
        courseName: value.name,
        img: value.photo,
        createdAt: noti.createdAt,
        isSeen: noti.isSeen,
      });
    });
  });
  return dataConv;
};

const Notification = () => {
  const userId = useAppSelector(selectUserId);
  const [notification, setNotification] = useState<any>();

  useEffect(() => {
    if (userId) {
      notificateAPI.getAllNotificateByUserId(userId).then((res) => {
        setNotification(convert2View(res.data));
      });
    }
  }, []);

  return (
    <div className="row">
      <h2>Thông báo từ các khóa học</h2>
      {notification &&
        notification.map((value:any) => <NotificateItem data={value} />)}
    </div>
  );
};

export default Notification;

import LoadOpacity from "app/common/loading/LoadOpacity";
import { useAppSelector } from "app/store/hooks";
import { selectCourseDetail, selectIsLoadingDetail } from "features/course/courseSlice";
import React from "react";
import "./styles.scss";


const CardDescriptionCourse: React.FC = () => {
  const load = useAppSelector(selectIsLoadingDetail);
  const courseDetail = useAppSelector(selectCourseDetail);

  if (load) return <LoadOpacity height="140px" radius="5px" />;

  return (
    <div className="card-des row">
      <h2>
        <strong>Giới thiệu khóa học</strong>
      </h2>
      <div className="content">{courseDetail?.mainContent}</div>
    </div>
  );
};

export default CardDescriptionCourse;

import { Pagination } from "@material-ui/lab";
import { ICourseSearch } from "app/@types/course";
import Container10CourseVer from "features/course/components/courses10ver";
import FilterBarVer from "features/course/components/filterbarver";
import { history } from "index";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { parse, stringify } from "query-string";
import courseAPI from "app/api/services/course.service";
import LoadOpacity from "app/common/loading/LoadOpacity";
import { IPaging } from "../topic";
import styled from "styled-components";

const Searching = () => {
  const [courseList, setCourseList] = useState<ICourseSearch[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sumOfResult, setSumOfResult] = useState(0);
  const [paging, setPaging] = useState<IPaging | undefined>();
  const parsed: any = parse(history.location.search);

  const handleChangePage = (page: number) => {
    let params = { ...parsed };
    params.page = page;
    history.push(`/khoahoc/timkiem?${stringify(params)}`);
  };

  useEffect(() => {
    courseAPI
      .getBySearchQuery(parsed)
      .then((res) => {
        setCourseList(res.data);
        setPaging({
          current: res.currentPage,
          total: res.totals,
        });
        setSumOfResult(res.totalsItem);
        window.scrollTo(0, 0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [history.location.search]);

  return (
    <SearchingWrapper className="search-page row">
      <div className="container-5 mt-1">
        {isLoading && <LoadOpacity width="30%" height={15} />}
        {!parsed.name && courseList.length === 0 ? (
          <h2>Kết quả tìm kiếm : 0</h2>
        ) : (
          <h2>
            {sumOfResult} kết quả tìm kiếm {parsed.name && `cho ${parsed.name}`}
          </h2>
        )}
      </div>
      <div className="container-5 col">
        <FilterBarVer />
        <Container10CourseVer isLoading={isLoading} data={courseList} />
      </div>
      <div className="w-100 d-f center mt-2">
        <Pagination
          count={paging?.total || 0}
          onChange={(e, page) => handleChangePage(page)}
          defaultPage={paging?.current || 0}
          shape="rounded"
        />
      </div>
    </SearchingWrapper>
  );
};

export default Searching;

const SearchingWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.responsive.desktopSmall}) {
    .container-5 {
      flex-direction: column !important;

      .fil-bar-ver {
        width: 100%;

        .content {
          height: fit-content !important;
          min-height: 140px !important;
        }
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import "./styles.scss";
import CoursesFilter5 from "features/course/components/coursesfilter5";
import Pagination from "@material-ui/lab/Pagination";
import { useParams } from "react-router-dom";
import categoryAPI from "app/api/services/category.service";
import { history } from "index";
import { ICategoryDetail } from "app/@types/category";
import LoadFb from "app/common/loading/LoadFb";

interface IParams {
  name: string;
}

export interface IPaging {
  current: number;
  total: number;
}

const Topic = () => {
  const params = useParams<IParams>();

  const [paging, setPaging] = useState<IPaging | undefined>();

  const [categoryDetail, setCategoryDetail] = useState<ICategoryDetail | undefined>();

  const handleChangePage = (event: any, newPage: number) => {
    categoryAPI
      .getDetailWithCourses(params.name, `${history.location.search.split("?")[1]}&page=${newPage}`)
      .then((res) => {
        setPaging({
          current: res.currentPage,
          total: res.totals,
        });
        setCategoryDetail(res.data);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        history.push("/khongtimthay");
      });
  };

  useEffect(() => {
    categoryAPI
      .getDetailWithCourses(params.name, history.location.search.split("?")[1])
      .then((res) => {
        setPaging({
          current: res.currentPage,
          total: res.totals,
        });
        setCategoryDetail(res.data);
      })
      .catch((err) => {
        history.push("/khongtimthay");
      });
  }, []);

  return (
    <div className="topic-page">
      <div className="container-10 mt-2 row">
        <div>
          <h1>{categoryDetail ? categoryDetail.name : <LoadFb width="100px" height={15} />}</h1>
        </div>

        <CoursesFilter5 data={categoryDetail?.courses} />

        <div className="w-100 d-f center mt-2">
          {paging && (
            <Pagination
              count={paging.total}
              defaultPage={paging.current}
              shape="rounded"
              onChange={handleChangePage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Topic;

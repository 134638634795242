import React from "react";
import js from "app/assets/js.jpg";
import LoadCircle from "app/common/loading/LoadCircle";
import LoadFb from "app/common/loading/LoadFb";
import { OrderDetailCourse } from "app/@types/payment";
import NumberFormat from "react-number-format";

interface IProps {
  data?: OrderDetailCourse;
}

const OrderCourseCard: React.FC<IProps> = ({ data }) => {
  if (!data)
    return (
      <div className="row">
        <div className="col w-100">
          <div className="wrap-cir" style={{ width: "20%" }}>
            <LoadCircle />
          </div>
          <div className="row w-50">
            <LoadFb height={15} width="50%" />
            <LoadFb height={15} width="50%" />
            <LoadFb height={15} width="50%" />
          </div>
        </div>
        <div className="bar-br"></div>
      </div>
    );
  return (
    <div className="row mb-1">
      <div className="order-cou col">
        <div className="wrap-img">
          <img src={data.course?.photo || js} alt="logo" />
        </div>
        <div className="wrap-des row">
          <div className="name">{data.course?.name}</div>
          <div className="des">{data.course?.title}</div>
        </div>
        <div className="wrap-price">
          <NumberFormat
            value={Math.ceil(data.price)}
            displayType={"text"}
            thousandSeparator={true}
          />{" "}
          đ
        </div>
      </div>
      <div className="bar-br"></div>
    </div>
  );
};

export default OrderCourseCard;

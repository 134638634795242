import React from "react";
import "./styles.scss";
import js from "app/assets/mern.jpg";
import { BsFillStarFill } from "react-icons/bs";
import Badge from "../badge";
import { VscThreeBars } from "react-icons/vsc";
import { AiFillPlayCircle } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import LoadOpacity from "app/common/loading/LoadOpacity";
import LoadCircle from "app/common/loading/LoadCircle";
import { ICoursePayment, ICourseSearch } from "app/@types/course";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

interface IProps {
  data?: ICourseSearch;
  fastCourse?: ICoursePayment;
}

const CardCourseHori: React.FC<IProps> = ({ data, fastCourse }) => {
  if (fastCourse)
    return (
      <Link to={`/khoahoc/xemkhoahoc/${fastCourse.id}`}>
        <div className={data ? "card-cou-hor col" : "card-cou-hor small col "}>
          <div className={data ? "wrap-img" : "wrap-small-img"}>
            <img src={fastCourse.photo || js} alt="logo" />
          </div>
          <div className="wrap-content row">
            <div className="name">{fastCourse.name}</div>
            <div className="title">{fastCourse.title}</div>
            <div className="auth">Tự học 3D</div>
            <div className="rating col">
              <div className="num">
                <div className="rating col">
                  <p style={{ marginRight: "5px" }}> 4.8</p>
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                </div>
              </div>
            </div>
            <div className="wrap-badge">
              <Badge content="BestSaller" />
            </div>
          </div>
        </div>
      </Link>
    );

  return (
    <div className={data ? "card-cou-hor col" : "card-cou-hor small col "}>
      <div className={data ? "wrap-img" : "wrap-small-img"}>
        {data ? (
          <img src={data?.photo || js} alt="logo" />
        ) : (
          <div className="wrap-cir">
            <LoadCircle />
          </div>
        )}
      </div>
      <div className="wrap-content row">
        <div className="name">
          {!data ? <LoadOpacity height={15} width="35%" /> : data?.name}
        </div>
        <div className="title">
          {!data ? <LoadOpacity height={20} width="85%" /> : data?.title}
        </div>
        <div className="auth">
          {!data ? <LoadOpacity height={15} width="30%" /> : "Tự học 3D"}
        </div>
        <div className="rating col">
          <div className="num">
            <div className="rating col">
              {!data ? (
                <LoadOpacity height={15} width="150px " />
              ) : (
                <>
                  <p style={{ marginRight: "5px" }}> 4.8</p>
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                  <BsFillStarFill />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="wrap-badge">
          {!!data ? (
            <Badge content="BestSaller" />
          ) : (
            <LoadOpacity height={15} width="10% " />
          )}
        </div>
      </div>
      {data && (
        <div className="wrap-summary">
          <div className="container-5 row">
            <h4 className="mt-1">Thông tin tóm tắt</h4>
            <div className="wrap-des col mt-1">
              <div className="wrap-icon">
                <VscThreeBars />
              </div>
              <div className="wrap-content">{data.sumOfSection} phần học</div>
            </div>
            <div className="wrap-des col mt-1">
              <div className="wrap-icon">
                <AiFillPlayCircle />
              </div>
              <div className="wrap-content">{data.sumOfLecture} bài học</div>
            </div>
            <div className="wrap-des col mt-1">
              <div className="wrap-icon">
                <BiTimeFive />
              </div>
              <div className="wrap-content">56 giờ</div>
            </div>

            <div className="price">
              <NumberFormat
                value={Math.ceil(data.price)}
                displayType={"text"}
                thousandSeparator={true}
              />
              {' '}đ
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardCourseHori;

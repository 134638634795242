import React, { useState } from "react";
import CardLecture from "../cardlecture";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./styles.scss";
import { ILecture, ISection } from "app/@types/course";
import _ from "lodash";
import { Tag } from "antd";

interface IProps {
  data: ISection;
  isPub?: boolean;
  setSelectLecture?: (data: ILecture) => void;
  lectureCurrentSeen?: string | null;
  idx?: any;
  publicVideoDemo?: string;
}

const CardSection: React.FC<IProps> = ({
  data,
  isPub = false,
  setSelectLecture,
  lectureCurrentSeen,
  idx,
  publicVideoDemo
}) => {
  const [isOpen, setisOpen] = useState(false);

  return (
    <div className="row">
      <div className="section col" onClick={(e) => setisOpen(!isOpen)}>
        <div className="icon d-f center">{isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
        <p>{data.name}</p>
        {lectureCurrentSeen &&
          _.findIndex(data.lectures, (row) => row.id === lectureCurrentSeen) !== -1 && (
            <Tag style={{ marginLeft: "20px" }} color="#87d068">
              Đang xem
            </Tag>
          )}
        <div className="sub-des">{`${data.lectures.length} bài học`}</div>
      </div>

      <div className={`wrap-lecs row ${!isOpen ? "d-none" : ""}`}>
        {_.sortBy(data.lectures, ["index"]).map((lc, id) => (
          <CardLecture
            isSeen={lectureCurrentSeen === lc.id}
            setSelectLecture={setSelectLecture}
            isPub={isPub}
            data={lc}
            publicVideoDemo={publicVideoDemo}
            key={id}
            isFrist={idx === 0 && id === 0}
          />
        ))}
      </div>
    </div>
  );
};

export default CardSection;

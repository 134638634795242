import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICourse } from "app/@types/course";
import { OrderDetail } from "app/@types/payment";
import courseAPI from "app/api/services/course.service";
import { AppDispatch, AppThunk, RootState } from "app/store";
import { ICartState } from "./@types";

export const CARTS_NAME = "carts";

const initialState: ICartState = {
  carts: [],
};

const setItemsToCart = (data: ICourse[]) => {
  window.localStorage.setItem(
    CARTS_NAME,
    JSON.stringify(data.map((x) => x.id))
  );
};

const clearItemsToCart = () => {
  window.localStorage.removeItem(CARTS_NAME);
};

export const fetchCourseByIds =
  (): AppThunk => async (dispatch: AppDispatch) => {
    try {
      const ids = JSON.parse(
        window.localStorage.getItem(CARTS_NAME) + ""
      ) as string[];

      const res = await courseAPI.getByIds(ids || []);

      const getItemNotNull = res.data.filter((x) => x !== null);

      dispatch(setCourses(getItemNotNull));
    } catch (error) {
      clearItemsToCart();
    }
  };

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCourses(state, { payload }: PayloadAction<ICourse[]>) {
      state.carts = payload;
    },
    insertCourseToCart(state, { payload }: PayloadAction<ICourse>) {
      const index = state.carts.findIndex((x) => x.id === payload.id);

      if (index === -1) {
        state.carts.push(payload);
        setItemsToCart(state.carts);
      }
    },
    removeCourseFromCart(state, { payload }: PayloadAction<number>) {
      state.carts.splice(payload, 1);
      setItemsToCart(state.carts);
    },
    resetCart: (state) => initialState,
  },
});

const { actions, reducer } = cartSlice;

export const {
  setCourses,
  insertCourseToCart,
  removeCourseFromCart,
  resetCart,
} = actions;

export default reducer;

export const selectCarts = (state: RootState) => state.cart.carts;

export const selectCartLength = (state: RootState) => state.cart.carts.length;

export const selectTotalPrice = (state: RootState) =>
  Math.ceil(
    state.cart.carts.reduce((prev, next) => {
      return prev + next.price * (1 - next.percent);
    }, 0)
  );

export const selectCourseDetailsToPayment = (state: RootState) =>
  state.cart.carts.map((value) => {
    const data: OrderDetail = {
      courseId: value.id,
      courseName: value.name + "",
      price: Math.ceil(value.price * (1 - value.percent)),
      image: value.photo + "",
    };
    return data;
  });

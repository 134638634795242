import ActiveCourse from "features/account/components/activecourse";
import ChangePassword from "features/account/components/changepassword";
import CustomerCourse from "features/account/components/mycourse";
import Notification from "features/account/components/notification";
import OrderHsitory from "features/account/components/orderhistory";
import ProfileCustomer from "features/account/components/profile";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import ProfileMenu from "../../components/menu";

const Profile = () => {
  const match = useRouteMatch();

  return (
    <ProfileWrapper className="w-100">
      <div className="container-5 col">
        <div className="menu">
          <ProfileMenu />
        </div>
        <div className="content-pro">
          <Switch>
            <Route path={`${match.url}/doimatkhau`} exact component={ChangePassword} />
            <Route path={`${match.url}/thongbao`} exact component={Notification} />
            <Route path={`${match.url}/khoahoc`} exact component={CustomerCourse} />
            <Route path={`${match.url}/kichhoat`} exact component={ActiveCourse} />
            <Route path={`${match.url}/lsthanhtoan`} component={OrderHsitory} />
            <Route path={`${match.url}`} exact component={ProfileCustomer} />
          </Switch>
        </div>
      </div>
    </ProfileWrapper>
  );
};

export default Profile;

const ProfileWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.responsive.desktopSmall}) {
    .menu {
      display: none;
    }
    .content-pro{
      width: 100% !important;
    }
  }

  .container-5 {
    margin: 20px 0px;

    .menu {
      width: 20%;
      padding: 10px;
    }

    .content-pro {
      width: 80%;

      .container-wrap {
        margin: 10px 15px;
        background-color: white;
        border-radius: 10px;

        box-shadow: rgba(100, 100, 111, 0.15) 0px 7px 29px 0px;

        min-height: 300px;

        padding: 20px;

        h2 {
          margin-bottom: 15px;
        }
      }
    }
  }
`;

import { FormControlLabel } from "@material-ui/core";
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

interface IPropsCheckInput {
  checked: boolean;
  handleChange?: any;
  name: string;
  label: string;
}

const CheckInput: React.FC<IPropsCheckInput> = ({
  checked,
  handleChange,
  name,
  label,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          name={name}
        />
      }
      label={label}
    />
  );
};

export default CheckInput;

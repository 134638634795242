import React, { Fragment, useEffect, useState } from "react";
import ps from "app/assets/ps.png";
import g1 from "app/assets/gr-hm-1.svg";
import g2 from "app/assets/gr-hm-2.svg";
import g3 from "app/assets/gr-hm-3.svg";
import g4 from "app/assets/gr-hm-4.svg";
import "./styles.scss";
import { Link } from "react-router-dom";
import CoursesNew from "./components/coursesnew";
import CardCourse from "app/common/components/coursecard";
import c1 from "app/assets/c1.jpg";
import bgmain from "app/assets/bgmain.jpg";
import Slides from "./components/slides";
import { ICourse } from "app/@types/course";
import courseAPI from "app/api/services/course.service";
import { logoMini, mainBg, preview, previewStudent } from "app/constants/images";
import Blogs from "features/blog/pages/list";

const Home = () => {
  const [trendingCourse, settrendingCourse] = useState<ICourse[] | undefined>(undefined);

  useEffect(() => {
    courseAPI.getTop5().then((res) => {
      settrendingCourse(res.data);
    });
  }, []);

  return (
    <Fragment>
      <div className="wrap-bg-main">
        <img src={bgmain} alt="bg-main" />
        <div className="wrap-main-cont">
          <h1> Tự Học 3D Online </h1>
          <h2>Nơi giúp bạn biến Đam Mê thành Sự Thật</h2>
        </div>
      </div>
      <div className="home-page">
        <div className="pad-home">
          <div className="wrap-bg col">
            <div className="content row w-50">
              <h2>
                Tự Học 3D Online nơi giúp các bạn tự học 3D một cách dễ dàng và chất lượng cao nhất
              </h2>
              <p>
                Các khóa học theo chuẩn quy trình đi làm tại các công ty lớn trong ngành như Sparx,
                GlassEgg, Bombus … Ở đây bạn sẽ được học tất cả các bước để tạo ra một sản phẩm 3D
                hoàn thiện ở từng khóa học . Học các phần mềm trong ngành như Zbrush, Maya,
                Substance Painter…
              </p>
              <div className="wrap-btn">
                <Link to="/taikhoan/dangnhap" className="btn">
                  Tham gia ngay
                </Link>
              </div>

              <div className="icons col">
                <div className="w-6">
                  <img src={logoMini[0]} alt="react" className="icon icon3" />
                </div>
                <div className="w-6">
                  <img src={logoMini[1]} alt="react" className="icon adobe a1" />
                </div>
                <div className="w-6 hiden">
                  <img src={logoMini[2]} alt="react" className="icon icon3" />
                </div>
                <div className="w-6 hiden">
                  <img src={logoMini[3]} alt="react" className="icon adobe a1" />
                </div>

                <div className="w-6">
                  <img src={ps} alt="react" className="icon adobe a3" />
                </div>
              </div>
            </div>

            <div className="bg-img w-50 d-f">
              <img src={mainBg} alt="pic1" className="pic1" />
            </div>
          </div>
        </div>

        <div className="pad-home">
          {trendingCourse && trendingCourse.length > 0 && (
            <CoursesNew title="Những khóa học mới nhất">
              {!trendingCourse ? (
                <div className="courses-content col">
                  <CardCourse loading={true} />
                  <CardCourse loading={true} />
                  <CardCourse loading={true} />
                  <CardCourse loading={true} />
                </div>
              ) : (
                <div className="courses-content col">
                  {trendingCourse.map((value) => (
                    <CardCourse
                      author="Hưng Trần"
                      title={value.title || ""}
                      image={value.photo || c1}
                      price={value.price}
                      to={`/khoahoc/${value.id}`}
                      isNew
                    />
                  ))}
                </div>
              )}
            </CoursesNew>
          )}
        </div>
        <div className="wrap-des col">
          <div className="item">
            <img src={g4} alt="g4" />
            <p className="des">800</p>
            <p>Hơn 800 học viên đã đăng ký</p>
          </div>
          <div className="item">
            <img src={g1} alt="g1" />
            <p className="des">100%</p>
            <p>Mức độ hài lòng của học viên</p>
          </div>
          <div className="item">
            <img src={g2} alt="g2" />
            <p className="des">5000</p>
            <p>Hơn 5000 lượt truy cập mỗi ngày</p>
          </div>
          <div className="item">
            <img src={g3} alt="g3" />
            <p className="des">100%</p>
            <p>Nâng cao các skill vào trong các dự án thực tế</p>
          </div>
        </div>

        <div className="pad-home">
          <div className="row bg-white">
            <div>
              <h2 className="mt-1">Các đánh giá từ học viên</h2>
              <div className="cap">
                Đây là nhưng đánh giá chân thực đến từ học viên trong quá trình học
              </div>
            </div>

            <div className="container-imgs">
              <Slides normal slides={previewStudent} />
            </div>
          </div>

          <div className="row bg-white">
            <div>
              <h2 className="mt-1">Các sản phẩm từ học viên</h2>
              <div className="cap">
                Những sản phẩm học design viên tự tay làm trong và sau quá trình học
              </div>
            </div>

            <div className="container-imgs">
              <Slides classes="strict-img-pv" slides={preview} />
            </div>
          </div>
        </div>

        <div className="pad-home">
          <div className="row">
            <div className="wrap-blog-title">
              <Link to="/blog">
                <h2 className="mt-1 blog-title">Blog chia sẻ</h2>
              </Link>
            </div>
          </div>
        </div>
        <div className="pad-home">
          <div className="row wrap-blogs">
            <Blogs isPage={false} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;

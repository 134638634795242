export interface IloginRequest {
  namelogin: string;
  password: string;
}

export class ResetPassByCode {
  isVerify = true;
  email = '';
  code = '';
  password = '';

  constructor(data?: Partial<ResetPassByCode>) {
    if (data)
      Object.assign(this, data)
  }
}
import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Controller, Control } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

type option = {
  label: string;
  value: string | boolean;
};

interface IProps {
  label: string;
  options: option[];
  name: string;
  control: Control<Record<string, any>>;
  defaultValue: string;
  isRow?: boolean;
}

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color: "#F64942",
    },
  },
  checked: {},
});

const RadioInput: React.FC<IProps> = ({
  label,
  options,
  name,
  control,
  defaultValue,
  isRow = false,
}) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => {
        return (
          <FormControl component="fieldset" className="mt-2">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
              aria-label={name}
              name={name}
              className={isRow ? "row" : "col"}
              value={value}
              onChange={onChange}
            >
              {options.map((item, id) => (
                <FormControlLabel
                  value={item.value}
                  key={id}
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      }}
    ></Controller>
  );
};

export default RadioInput;

import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { fetchCategories, selectCategories } from "features/nav/NavbarSlice";
import { AiOutlineLogin } from "react-icons/ai";
import { BiUserPlus } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';

interface CategoryLinkProps {
  name: string;
  to: string;
  onClick?: () => void;
}

interface PublicNavProps {
  onClick?: () => void;
}

const PublicNav = ({ onClick }: PublicNavProps) => {
  const [open, setOpen] = useState(false);

  const categories = useAppSelector(selectCategories);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!categories) {
      dispatch(fetchCategories());
    }
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleNavClick = () => {
    onClick && onClick();
  };

  return (
    <NavPubWrapper>
      <List>
        <NavLink exact to="/taikhoan/dangnhap" activeClassName="active-item">
          <ListItem button onClick={() => handleNavClick()}>
            <ListItemText primary="Đăng nhập" />
            <AiOutlineLogin />
          </ListItem>
        </NavLink>
        <NavLink exact to="/taikhoan/dangky" activeClassName="active-item">
          <ListItem button onClick={() => handleNavClick()}>
            <ListItemText primary="Đăng ký" />
            <BiUserPlus />
          </ListItem>
        </NavLink>
        <NavLink exact to="/khoahoc/timkiem" activeClassName="active-item">
          <ListItem button onClick={() => handleNavClick()}>
            <ListItemText primary="Tìm kiếm" />
            <SearchIcon />
          </ListItem>
        </NavLink>
        <ListItem button onClick={handleClick}>
          <ListItemText primary="Thể loại khóa học" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className="nested">
              <ListItemText primary="Starred" />
            </ListItem>

            {categories &&
              categories.map((value) => (
                <CategoryLink
                  onClick={handleNavClick}
                  to={`/khoahoc/chude/${value.id}`}
                  name={value.name}
                />
              ))}
          </List>
        </Collapse>
      </List>
    </NavPubWrapper>
  );
};

export default PublicNav;

const CategoryLink = ({ name, to, onClick }: CategoryLinkProps) => {
  return (
    <NavLink to={to} activeClassName="active-item">
      <ListItem button onClick={() => onClick && onClick()} className="nested">
        <ListItemText primary={name} />
      </ListItem>
    </NavLink>
  );
};

const NavPubWrapper = styled.div`
  width: 200px;
  .nested {
    padding-left: 16px;
  }
  .active-item {
    .nested {
      background-color: ${({ theme }) => theme.mainColor} !important;
    }
    .MuiListItem-button {
      background-color: ${({ theme }) => theme.mainColor} !important;
    }
    color: white;
  }
`;

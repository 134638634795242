import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Blog } from "./interfaces/blog.interface";
import { BlogQuery } from "./models/query.model";

interface BlogState {
    blogs: Blog[],
    filter: BlogQuery
}

const initialState: BlogState = {
    blogs: [],
    filter: new BlogQuery()
};


const blogSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {
        setBlogs(state, { payload }: PayloadAction<Blog[]>) {
            state.blogs = payload
        },
        setBlogQuery(state, { payload }: PayloadAction<BlogQuery>) {
            state.filter = payload
        },
    },
});

const { actions, reducer } = blogSlice;

export const {
    setBlogs,
    setBlogQuery
} = actions;

export default reducer;

export const selectBlogs = (state: RootState) => state.blog.blogs

export const selectBlogQuery = (state: RootState) => state.blog.filter
import { ICourseNotification } from "app/@types/course";
import { IGetResponse } from "../responses/base";
import requests from "./base.service";

const NOTI_URL = process.env.REACT_APP_NOTIFICATION_URL as string;
const endPoint = `${NOTI_URL}`;

const notificateAPI = {
  getAllNotificateByUserId: (
    userId: string
  ): Promise<IGetResponse<ICourseNotification[]>> =>
    requests.get(`${endPoint}notification/user/${userId}`),
};

export default notificateAPI;

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import courseReducer from "features/course/courseSlice";
import cartReducer from "features/cart/cartSlice";
import userProfileReducer from "features/account/userProfileSlice";
import navSlice from "features/nav/NavbarSlice";
import blogReducer from "features/blog/blogSlice";

export const store = configureStore({
  reducer: {
    course: courseReducer,
    cart: cartReducer,
    user: userProfileReducer,
    nav: navSlice,
    blog: blogReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

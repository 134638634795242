import React, { Fragment, useEffect } from "react";
import Navigation from "features/nav";
import "./index.scss";
import { Route, Switch, withRouter } from "react-router-dom";
import { routersMap } from "app/routers";
import Footer from "features/footer";
import { ToastContainer } from "react-toastify";
import { useAppDispatch } from "app/store/hooks";
import { getCurrentUser } from "features/account/userProfileSlice";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { history } from "index";
import ReactGA from "react-ga";
ReactGA.initialize("UA-217207826-1");

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const isHome = history.location.pathname === "/";

  return (
    <Fragment>
      <ToastContainer />
      <MessengerCustomerChat pageId="364840657667105" appId="575176957123623" />
      <div className="row">
        <Navigation />
        <div className={isHome ? "main-content" : `main-content mt-home`}>
          <Switch>
            {routersMap.map((route, id) => (
              <Route key={id} component={route.component} exact={route.exact} path={route.path} />
            ))}
          </Switch>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default withRouter(App);

import React from "react";
import { FiShoppingCart } from "react-icons/fi";
import "./styles.scss";
import { useAppSelector } from "app/store/hooks";
import { selectCartLength } from "features/cart/cartSlice";
import { Link } from "react-router-dom";

const Cart = () => {
  const cartLength = useAppSelector(selectCartLength);

  return (
    <Link to="/giohang">
    <div className="wrap-cart">
      <div className="wrap-cart-d">
        <div className="len">{cartLength}</div>
        <FiShoppingCart />
      </div>
    </div>
    </Link>
  );
};

export default Cart;

import blogServices from "app/api/services/blog.service";
import NotFound from "app/layout/NotFound";
import { Blog } from "features/blog/interfaces/blog.interface";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const BlogDetail = () => {
  const [blogDetail, setBlogDetail] = useState<Blog | undefined>(undefined);

  const [notExists, setNotExists] = useState(false);

  const params = useParams<any>();

  useEffect(() => {
    if (params.id) {
      blogServices
        .getById(params.id)
        .then((res) => {
          setBlogDetail(res);
        })
        .catch(() => {
          setNotExists(true);
        });
    } else {
      setNotExists(true);
    }
  }, []);

  if (notExists) return <NotFound />;

  return (
    <BlogDetailStyles>
      {blogDetail?.avatar && (
        <div className="wrap-avatar">
          <img src={blogDetail?.avatar} alt="avatr" />
          <p>{blogDetail?.title}</p>
        </div>
      )}
      <div className="wrap-content">
        <div className="wrap-content-bg">
          <div dangerouslySetInnerHTML={{ __html: blogDetail?.content || "" }}></div>
        </div>
      </div>
    </BlogDetailStyles>
  );
};

const BlogDetailStyles = styled.div`
  display: flex;
  flex-direction: column;

  .wrap-content {
    min-height: 20vh;
    width: 100%;
    padding: 0 17%;
    &-bg {
      width: 100%;
      background-color: white;
      border-radius: 4px;
      min-height: 20vh;
      box-shadow: rgba(50, 50, 93, 0.2) 0px 6px 12px -2px, rgba(0, 0, 0, 0.1) 0px 3px 7px -3px;
      padding: 12px 1rem;
    }
  }

  .wrap-avatar {
    width: 100%;
    height: 73vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(36%);
      position: absolute;
    }

    p {
      z-index: 10;
      color: white;
      font-size: 42px;
      font-weight: 700;
    }
  }
`;

export default BlogDetail;

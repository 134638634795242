import React, { useState } from "react";
import { SendEmailResetPasswordStyles } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "app/common/form/textinput";
import ReCAPTCHA from "react-google-recaptcha";
import { showError } from "app/notification";
import userAPI from "app/api/services/user.service";
import { Alert } from "@material-ui/lab";
import { Spin } from "antd";

interface Props {
  setForgetPW: Function;
}

const SendEmailResetPasswordSchema = yup.object().shape({
  email: yup.string().required("Vui lòng nhập email").email("Email sai định dạng"),
});

const SendEmailResetPassword = ({ setForgetPW }: Props) => {
  const [captcha, setCaptcha] = useState<string | null>("");

  const [finish, setFinish] = useState(false);

  const [loading, setLoading] = useState(false);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(SendEmailResetPasswordSchema),
  });

  const onSubmit = (data: any) => {
    if (!captcha) {
      showError("Vui lòng thực hiện xác thực Captcha");
      return;
    }

    setLoading(true);

    userAPI
      .sendEmailResetPW(data.email)
      .then(() => {
        setFinish(true);
      })
      .catch((err) => {
        showError(err.error, 9000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCaptchaChange = (data: string | null) => {
    setCaptcha(data);
  };

  return (
    <SendEmailResetPasswordStyles>
      {loading && (
        <div className="loading">
          <Spin />
        </div>
      )}
      {!finish ? (
        <>
          <div className="head col center">
            <h2>Vui Lòng Email Để Đặt Lại Mật Khẩu</h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              classes="mt-2 w-100"
              label="Email"
              control={control}
              errors={errors}
              name="email"
            />
            <div className="captcha mt-1">
              <ReCAPTCHA
                sitekey="6Lc1TgUeAAAAANwQ0OH4nBNFgoDtkc_woxF2es4r"
                onChange={onCaptchaChange}
              />
            </div>
            <button className="btn-login mt-2 w-100 d-f center" type="submit">
              Đặt lại mật khẩu
            </button>
          </form>
        </>
      ) : (
        <Alert severity="success">Đã gửi Email đặt lại mật khẩu. Vui lòng check mail !</Alert>
      )}
      <div className="d-f center w-100 f-pass" onClick={() => setForgetPW(false)}>
        Đăng nhập
      </div>
    </SendEmailResetPasswordStyles>
  );
};

export default SendEmailResetPassword;

import LoadFb from "app/common/loading/LoadFb";
import { createConditionList } from "app/helper/convert";
import { useAppSelector } from "app/store/hooks";
import {
  selectCourseDetail,
  selectIsLoadingDetail,
} from "features/course/courseSlice";
import React from "react";
import CardRequireItem from "../cardrequireitem";
import "./styles.scss";

interface IProps {
  title: string;
  isConditionRequirement: boolean;
}

const CardRequire: React.FC<IProps> = ({
  title,
  isConditionRequirement = true,
}) => {
  const loading = useAppSelector(selectIsLoadingDetail);
  const courseDetail = useAppSelector(selectCourseDetail);

  if (loading)
    return (
      <div className="card-req row">
        <div>
          <h2>{title}</h2>
        </div>
        <div className="wrap-content col w-100">
          <div className="row w-100">
            <LoadFb width="60%" height="40px" radius="5px" />
            <LoadFb width="60%" height="40px" radius="5px" />
            <LoadFb width="60%" height="40px" radius="5px" />
          </div>
          <div className="row w-100">
            <LoadFb width="60%" height="40px" radius="5px" />
            <LoadFb width="60%" height="40px" radius="5px" />
          </div>
        </div>
      </div>
    );

  const createItems = (isLeft: boolean = true) => {
    let arrElement = [];
    const arr = isConditionRequirement
      ? createConditionList(courseDetail?.conditionRequirement)
      : createConditionList(courseDetail?.customerSegment);
    if (arr.length === 0) return [];
    if (isLeft)
      for (let index = 0; index < 5; index++) {
        if (index === arr.length) break;
        arrElement.push(<CardRequireItem key={index} content={arr[index]} />);
      }
    else {
      for (let index = 5; index < 10; index++) {
        if (index > arr.length-1) break;
        arrElement.push(<CardRequireItem key={index} content={arr[index]} />);
      }
    }
    return arrElement;
  };

  return (
    <div className="card-req row">
      <div>
        <h2>{title}</h2>
      </div>
      <div className="wrap-content col w-100">
        <div className="row w-100">{createItems()}</div>
        <div className="row w-100">{createItems(false)}</div>
      </div>
    </div>
  );
};

export default CardRequire;

import LoadFb from "app/common/loading/LoadFb";
import React from "react";
import data from "app/assets/Data.png";
import "./styles.scss";
import CardBenifitDescription from "../cardbendes";
import { useAppSelector } from "app/store/hooks";
import { selectCourseDetail, selectIsLoadingDetail } from "features/course/courseSlice";


const CardBenefit: React.FC = () => {

  const load = useAppSelector(selectIsLoadingDetail);
  const courseDetail = useAppSelector(selectCourseDetail);

  if (load)
    return (
      <div className="card-ben-loading row">
        <LoadFb height="28px" radius="3px" />
        <LoadFb height="28px" radius="3px" />
        <LoadFb height="28px" radius="3px" />
        <LoadFb height="28px" radius="3px" />
        <LoadFb height="28px" radius="3px" />
      </div>
    );

  return (
    <div className="card-ben">
      <div className="row w-100">
        <h3>Lợi ích từ khóa học</h3>
        {courseDetail &&
          courseDetail.caseStudies.map((value) => (
            <CardBenifitDescription
              key={value.id}
              content={value.description}
            />
          ))}
      </div>
      <img src={data} alt="logo" />
    </div>
  );
};

export default CardBenefit;

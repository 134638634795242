import { ICoursePayment } from "app/@types/course";
import {
  IPaymentCourses,
  OrderHistoryUnpay,
  OrderInsertSuccess,
  OrderPayment,
} from "app/@types/payment";
import { IGetResponse } from "../responses/base";
import requests from "./base.service";

const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL as string;
const endPoint = `${PAYMENT_URL}`;

const paymentAPI = {
  createOrder: (
    data: IPaymentCourses
  ): Promise<IGetResponse<OrderInsertSuccess>> =>
    requests.post(`${endPoint}order`, { order: data }),

  orderHistoryUnpay: (
    userId: string
  ): Promise<IGetResponse<OrderHistoryUnpay[]>> =>
    requests.get(`${endPoint}payment/unpay/courses/${userId}`),

  orderHistoryHaspay: (
    userId: string
  ): Promise<IGetResponse<OrderHistoryUnpay[]>> =>
    requests.get(`${endPoint}payment/courses/${userId}`),

  paymentByCode: (data: OrderPayment) =>
    requests.post(`${endPoint}order/verify`, data),

  getAllCourseHasPayment: (
    userId: string
  ): Promise<IGetResponse<ICoursePayment[]>> =>
    requests.get(`${endPoint}payment/courses/detail/${userId}`),

  checkUserBuyCourse: (userId: string, courseId: string) =>
    requests.post(`${endPoint}payment/checkpayment`, { userId, courseId }),

  userDeleteOrder: (userId: string, orderId: string) => requests.del(`${endPoint}order/user/${userId}/${orderId}`)
};

export default paymentAPI;

import styled from 'styled-components'
import { DisplayRow } from 'styles/components/div.styled'

export const ResetPasswordStyles = styled(DisplayRow)`
    width: 90%;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-color: white;
    padding: 1rem;
    position: relative;
    overflow: hidden;

    h1{
        font-weight: bold !important;
    }

    .btn-reset-pass{
        width: 100%;
        padding: 1rem 0;
        border-radius: 8px;
        font-size: 16px;
        background-color: ${({ theme }) => theme.mainColor};
        color: white;
        cursor: pointer;
    }

    .loading{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: white;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-spin-dot-item{
            background-color: ${({ theme }) => theme.mainColor};;
        }
    }

`
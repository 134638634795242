import {
  ICourse,
  ICourseDetail,
  ICourseFilter,
  ICourseSearch,
  ICourseStudyDetail,
} from "app/@types/course";
import { ICourseStudy, SeenCourse } from "../requests/course";
import {
  IGetFilterResponse,
  IGetFilterWithItemsResponse,
  IGetResponse,
} from "../responses/base";
import requests from "./base.service";

const COURSE_URL = process.env.REACT_APP_COURSE_URL as string;
const endPoint = `${COURSE_URL}courses`;

const courseAPI = {

  getByQuery: (
    courseFilter: ICourseFilter
  ): Promise<IGetFilterResponse<ICourse[]>> =>
    requests.getFilterWithObject(`${endPoint}`, { courseFilter }),

  getById: (id: string): Promise<IGetResponse<ICourseDetail>> =>
    requests.get(`${endPoint}/${id}`),

  getByIds: (ids: string[]): Promise<IGetResponse<ICourse[]>> =>
    requests.getFilterWithObject(`${endPoint}/ids`, { ids: ids }),

  getBySearchQuery: (
    courseFilter: ICourseFilter
  ): Promise<IGetFilterWithItemsResponse<ICourseSearch[]>> => {
    courseFilter.isSearch = true;
    if (courseFilter.page)
      courseFilter.page = parseInt(courseFilter.page as any);
    if (courseFilter.size)
      courseFilter.size = parseInt(courseFilter.size as any);
    if (courseFilter.priceFrom)
      courseFilter.priceFrom = parseInt(courseFilter.priceFrom as any);
    if (courseFilter.priceTo)
      courseFilter.priceTo = parseInt(courseFilter.priceTo as any);
    return requests.getFilterWithObject(`${endPoint}/filter`, courseFilter);
  },

  getCourseForStudy: (data: ICourseStudy): Promise<IGetResponse<ICourseStudyDetail>> =>
    requests.post(`${endPoint}/study`, data),

  setSeenLecture: (data: SeenCourse) => 
    requests.get(`${COURSE_URL}lectures/setSeen/${data.userId}/${data.lectureId}`),

  getTop5: (): Promise<IGetResponse<ICourse[]>> => requests.get(`${COURSE_URL}trending`)
};

export default courseAPI;

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import App from "app/layout/App";
import { Router } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from "react-redux";
import { store } from "app/store";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "app/layout/ScrollToTop";
import { ThemeProvider } from "styled-components";
import theme from "styles/theme";
import GlobalStyles from "styles/global/main.styled";
import "antd/dist/antd.css";

export const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <App />
          <GlobalStyles />
        </ThemeProvider>
      </ScrollToTop>
    </Router>
  </Provider>,
  document.getElementById("root")
);

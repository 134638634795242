import { Grid } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";

interface IProps {
  name: string;
  price: number;
}

const CourseDetailPreview: React.FC<IProps> = ({ name, price }) => {
  return (
    <Grid container>
      <Grid item xs={10}>
        {name}
      </Grid>
      <Grid item xs={2}>
        <NumberFormat
          value={price}
          displayType={"text"}
          thousandSeparator={true}
        />
      </Grid>
    </Grid>
  );
};

export default CourseDetailPreview;

import TextInput from "app/common/form/textinput";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RadioInput from "app/common/form/radioinput";
import { Link } from "react-router-dom";
import { useAppDispatch } from "app/store/hooks";
import { userRegisterAccount } from "features/account/userProfileSlice";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { showError } from "app/notification";
import PasswordInput from "app/common/form/passwordinput";

const SignupSchema = yup.object().shape({
  displayName: yup.string().required("Tên bắt buộc"),
  email: yup.string().required("Email bắt buộc").email("Email sai định dạng"),
  password: yup
    .string()
    .required("Mật khẩu bắt buộc")
    .min(8, "Mật khẩu ít nhất 8 kí tự")
    .max(20, "Mật khẩu tối đa 20 kí tự")
    .matches(/[A-Z]+/, "Phải có chữ in hoa")
    .matches(/[0-9]+/, "Phải có chữ số 0 đến 9")
    .matches(/[a-z]+/, "Phải có chữ thường")
    .matches(/[-[/\]{}()@*+?.,\\^$|#\s]/g, "Phải có ký tự đặt biệt"),
  gender: yup.string().default("true"),
  phoneNumber: yup.string().required("Số điện thoại là bắt buộc"),
});

const Registry = () => {
  const [captcha, setCaptcha] = useState<string | null>("");

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      gender: "true",
    },
  });

  const dispatch = useAppDispatch();

  const onSubmit = (data: any) => {
    if (!captcha) {
      showError("Vui lòng thực hiện xác thực Captcha");
      return;
    }
    data.gender = data.gender === "true";
    dispatch(userRegisterAccount(data));
  };

  const onCaptchaChange = (data: string | null) => {
    setCaptcha(data);
  };

  return (
    <RegistWrapper>
      <div className="form-regis row">
        <div className="col">
          <h2>Tạo tài khoản</h2>
          <Link className="col" style={{ marginLeft: "1rem" }} to="/taikhoan/dangnhap">
            Bạn đã có tài khoản ?<p> Đăng nhập</p>
          </Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput label="Họ và tên" name="displayName" control={control} errors={errors} />
          <TextInput label="Số điện thoại" name="phoneNumber" control={control} errors={errors} />
          <TextInput label="Email" name="email" control={control} errors={errors} />
          <PasswordInput label="Mật khẩu" name="password" control={control} errors={errors} />
          <div className="col foot">
            <div className="w-50">
              <RadioInput
                defaultValue="true"
                label="Giới tính"
                control={control}
                name="gender"
                options={[
                  { label: "Nam", value: "true" },
                  { label: "Nữ", value: "false" },
                ]}
              />
            </div>
          </div>
          <div className="captcha">
            <ReCAPTCHA
              sitekey="6Lc1TgUeAAAAANwQ0OH4nBNFgoDtkc_woxF2es4r"
              onChange={onCaptchaChange}
            />
          </div>

          <button className="w-100 p-1 mt-1 btn-regis" type="submit">
            Đăng ký
          </button>
        </form>
      </div>
    </RegistWrapper>
  );
};

export default Registry;

const RegistWrapper = styled.div`
  width: 90%;
  margin-top: 16px;

  @include media("<=tilhome") {
    padding: 10px;
    margin-top: 10px;
  }

  .form-regis {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
    padding: 0.75rem;

    .captcha > div > div {
      display: flex;
      justify-content: center;
    }

    .col {
      align-items: center;
      @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
        flex-direction: column !important;
        align-items: flex-start;
        .col {
          flex-direction: row !important;
        }
        a {
          margin-left: 0 !important;
        }
      }
      a {
        display: flex;
        flex-direction: row;
        font-size: 0.9rem;
        p {
          margin-left: 0.1rem;
          color: #ea3939;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .MuiTextField-root {
      margin-top: 12px;
      width: 100%;
    }

    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      padding: 10px;
    }

    .foot {
      @media (max-width: ${({ theme }) => theme.responsive.tabletSmall}) {
        flex-direction: column !important;

        .w-50 {
          width: 100% !important;
        }
        .col {
          width: 100% !important;
          flex-direction: row !important;
        }
      }
    }

    .btn-regis {
      background-color: #fdd835;

      font-size: 1.4rem;
      color: #4a4a4a;
      cursor: pointer;
    }
  }
`;

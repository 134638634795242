import LoadFb from "app/common/loading/LoadFb";
import LoadOpacity from "app/common/loading/LoadOpacity";
import { useAppSelector } from "app/store/hooks";
import { selectCourseDetail, selectIsLoadingDetail } from "features/course/courseSlice";
import _ from "lodash";
import React from "react";
import CardSection from "../cardsection";
import "./styles.scss";

interface Props {
  isPub?: boolean;
}

const CardVideos = ({ isPub = false }: Props) => {
  const load = useAppSelector(selectIsLoadingDetail);
  const courseDetail = useAppSelector(selectCourseDetail);

  if (load)
    return (
      <div className="card-videos-loading row">
        <LoadFb width="19%" height="20px" radius="3px" />
        <LoadOpacity />
        <LoadOpacity />
        <LoadOpacity />
        <LoadOpacity />
        <LoadOpacity />
      </div>
    );

  const createInfoTile = () => {
    let content: string = "";
    if (courseDetail) {
      if (courseDetail.sections.length > 0) {
        content = `${courseDetail.sections.length} Phần`;

        let countLeatures = 0;
        courseDetail.sections.forEach((sc) => {
          sc.lectures.forEach(() => {
            countLeatures += 1;
          });
        });

        content = `${content}, ${countLeatures} Bài học`;
      }
    }
    return content;
  };

  return (
    <div className="card-videos row">
      <h2>Nội dung khóa học</h2>
      <p>{createInfoTile()}</p>

      <div className="row">
        {courseDetail &&
          _.sortBy(courseDetail.sections, ["index"]).map((sc, id) => (
            <CardSection
              publicVideoDemo={courseDetail.publicLinkPreview}
              isPub={isPub}
              idx={id}
              data={sc}
            />
          ))}
      </div>
    </div>
  );
};

export default CardVideos;

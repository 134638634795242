import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { logout, selectUserProfile } from "features/account/userProfileSlice";
import PublicNav from "features/nav/components/publicnav";
import React from "react";
import { AiOutlineBell } from "react-icons/ai";
import { BiKey } from "react-icons/bi";
import { BsFillCollectionPlayFill } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { RiLockPasswordLine, RiProfileLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { history } from "index";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
interface Props {
  onClick?: () => void;
  otherCourses?: boolean;
}

const ProfileMenu = ({ onClick, otherCourses = false }: Props) => {
  const user = useAppSelector(selectUserProfile);

  const dispatch = useAppDispatch();

  const createSlice = (name: string | undefined) => {
    if (!name) return "U";
    const arr = name.split(" ");
    return arr[arr.length - 1][0];
  };

  if (!user) return <PublicNav onClick={() => onClick && onClick()} />;

  const handleClick = () => {
    history.push("");
    dispatch(logout());
  };

  return (
    <MenuWrapper className="row" onClick={() => onClick && onClick()}>
      <div className="wrap-pro col">
        <div className="avatar d-f center">{createSlice(user?.username) || "N"}</div>
        <div className="wrap-info row">
          <div className="name">{user?.username || "Người dùng"}</div>
          <div className="role">Học viên</div>
        </div>
      </div>
      <div className="links row">
        <NavLink exact className="wrap-link col" to="/taikhoan/trangcanhan">
          <FaRegUserCircle />
          <div className="content">Thông tin cá nhân</div>
        </NavLink>
        <NavLink exact className="wrap-link col" to="/taikhoan/trangcanhan/thongbao">
          <AiOutlineBell />
          <div className="content">Thông báo từ các khóa học</div>
        </NavLink>
        <NavLink exact className="wrap-link col" to="/taikhoan/trangcanhan/doimatkhau">
          <RiLockPasswordLine />
          <div className="content">Đổi mật khẩu</div>
        </NavLink>
        <NavLink exact className="wrap-link col" to="/taikhoan/trangcanhan/khoahoc">
          <BsFillCollectionPlayFill />
          <div className="content">Khóa học của tôi</div>
        </NavLink>
        <NavLink exact className="wrap-link col" to="/taikhoan/trangcanhan/kichhoat">
          <BiKey />
          <div className="content">Kích hoạt khóa học</div>
        </NavLink>
        <NavLink exact className="wrap-link col" to="/taikhoan/trangcanhan/lsthanhtoan">
          <RiProfileLine />
          <div className="content">Lịch sử mua hàng</div>
        </NavLink>
        {otherCourses && (
          <NavLink exact className="wrap-link col" to="/khoahoc/timkiem">
            <LibraryBooksIcon />
            <div className="content">Các khóa học khác</div>
          </NavLink>
        )}
        <div className="wrap-link col" onClick={handleClick}>
          <ExitToAppIcon />
          <div className="content">Đăng xuất</div>
        </div>
      </div>
    </MenuWrapper>
  );
};

export default React.memo(ProfileMenu);

const MenuWrapper = styled.div`
  min-height: 300px;
  background-color: white;
  box-shadow: 2px 2px 10px #e2e2e2;
  border-radius: 10px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.responsive.tablet}) {
    box-shadow: none !important;
  }

  .wrap-pro {
    padding: 15px;
    .avatar {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: #999883;
      font-size: 2rem;
      color: white;
      margin-right: 12px;
      box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    }
    .wrap-info {
      justify-content: center;
      .name {
        font-size: 18px !important;
        font-weight: 700;
      }
      .role {
        font-size: 13px;
        color: #495057;
        font-weight: 600;
      }
    }
  }

  .links {
    margin: 0px 10px;
    border-radius: 10px;

    .active {
      background-color: #ffbe00;
      color: white;
      svg {
        color: white !important;
      }
    }

    .wrap-link {
      padding: 10px 5px;
      align-items: center;
      border-radius: 5px;
      &.active {
        color: white !important;
      }
      svg {
        margin-right: 12px;
        width: 20px;
        height: 20px;
        color: #bebebe;
      }
      margin-bottom: 13px;
      padding-left: 10px;
      font-style: normal !important;
      font-size: 14px;
      transition: all 0.3s ease 0s;

      &:hover {
        background-color: #ffbe00;
        color: white !important;
        svg {
          color: white !important;
        }
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";

interface IProps {
  color?: string | "red";
  content: string;
}

const Badge: React.FC<IProps> = ({ color = "", content }) => {
  return <BadgeWrapper className={`${color}`}>{content}</BadgeWrapper>;
};

const BadgeWrapper = styled.div`
  border-radius: 5px;
  width: fit-content;
  min-height: fit-content;
  padding: 4px 5px;
  background-color: #ffe799;
  color: #593d00;
  font-size: 12px !important;
  font-weight: bold;

  &.red {
    background-color: #ff9999;
    color: #521818;
  }
`;

export default Badge;

import l1 from 'app/assets/home/blender.png'
import l2 from 'app/assets/home/maya.png'
import l3 from 'app/assets/home/sp.png'
import l4 from 'app/assets/home/zbrush.png'
import main from 'app/assets/home/main.jpg'
import p1 from 'app/assets/posts/p1.jpg'
import p2 from 'app/assets/posts/p2.jpg'
import p3 from 'app/assets/posts/p3.jpg'
import p4 from 'app/assets/posts/p4.jpg'
import p5 from 'app/assets/posts/p5.jpg'
import p6 from 'app/assets/posts/p6.jpg'
import r1 from 'app/assets/reviews/r1.jpg'
import r2 from 'app/assets/reviews/r2.jpg'
import r3 from 'app/assets/reviews/r3.jpg'
import r4 from 'app/assets/reviews/r4.jpg'
import logoHeader from "app/assets/logo/l1.png"
import logoFooter from "app/assets/logo/l2.png"
import mk from "app/assets/mk.png"
import fp from "app/assets/forgetPW.svg"

export { logoHeader, logoFooter, mk, fp }

export const logoMini = [l1, l2, l3, l4]

export const preview = [
    {
        slides: [{
            srcImage: p1
        }, {
            srcImage: p2
        }, {
            srcImage: p3
        }, {
            srcImage: p4
        }]
    },
    {
        slides: [{
            srcImage: p5
        }, {
            srcImage: p6
        }]
    }
];

export const previewStudent = [
    {
        slides: [{
            srcImage: r1
        }, {
            srcImage: r2
        }, {
            srcImage: r3
        }, {
            srcImage: r4
        }]
    }]

export const mainBg = main

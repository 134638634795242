import { ACCESS_TOKEN } from "app/helper/tokenManager";
import { showError } from "app/notification";
import axios, { AxiosResponse } from "axios";
import { history } from "index";
import { toast } from "react-toastify";

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (error.message === "Network Error" && !error.response) {
    toast.error('Server Error');
    throw new Error('Network error');
  }
  const { status, config, headers } = error.response;
  if (
    status === 401 &&
    headers["www-authenticate"] ===
    'Bearer error="invalid_token", error_description="The token is expired"'
  ) {
    window.localStorage.removeItem("token");
  }
  if (status === 400 && config.method === "get") {
    history.push("/khongtimthay");
  }
  throw error.response.data;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  getFilterWithObject: (url: string, data: any) =>
    axios.post(url, data).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);
    return axios
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },
};

export default requests;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import "./styles.scss";
import Alert from "@material-ui/lab/Alert";
import { AiOutlineFilter } from "react-icons/ai";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckInput from "app/common/form/checkedinput";
import { parse, stringify } from "query-string";
import { history } from "index";
import { useAppSelector } from "app/store/hooks";
import { selectCategories } from "features/nav/NavbarSlice";

interface IPriceRange {
  from: number;
  to: number;
}

const FilterBarVer = () => {
  const categories = useAppSelector(selectCategories);

  const [priceRange, setPriceRange] = useState<IPriceRange>({
    from: 0,
    to: 0,
  });

  const [exp1, setExp1] = useState(false);

  const [exp2, setExp2] = useState(false);

  const [indexSelected, setIndexSelected] = useState(0);

  const handleChangePrice = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    if (value <= 30000000) {
      let priceClone: any = { ...priceRange };
      priceClone[name] = value;
      setPriceRange(priceClone);
    }
  };

  const handleClick = () => {
    let parsed: any = parse(history.location.search);
    if (indexSelected !== 0) parsed.categoryId = categories[indexSelected].id;
    priceRange.from !== 0 && (parsed.priceFrom = priceRange.from);
    priceRange.to !== 0 && (parsed.priceTo = priceRange.to);
    history.push(`/khoahoc/timkiem?${stringify(parsed)}`);
    setExp1(false);
    setExp2(false);
    window.scrollTo(0,0)
  };

  return (
    <div className="fil-bar-ver">
      <div className="content row">
        <div className="w-50 d-f">
          <Alert variant="filled" className="d-f center" icon={<AiOutlineFilter />}>
            Bộ lọc
          </Alert>
        </div>

        <Accordion title="Chủ đề" expanded={exp1}>
          <AccordionSummary
            onClick={() => setExp1(!exp1)}
            aria-controls="panel1a-content"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Thể loại</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <CheckInput
                handleChange={() => setIndexSelected(0)}
                checked={indexSelected === 0}
                label="Tất cả"
                name={"Tất cả"}
                key={"Tất cả"}
              />
              {categories.map((val, index) => (
                <CheckInput
                  handleChange={() => setIndexSelected(index + 1)}
                  checked={indexSelected === index + 1}
                  label={val.name}
                  name={val.id}
                  key={val.id}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={exp2}>
          <AccordionSummary
            onClick={() => setExp2(!exp2)}
            aria-controls="panel1a-content"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Giá</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <TextField
                onChange={handleChangePrice}
                name="from"
                label="Gía từ"
                type="number"
                value={priceRange.from}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <TextField
                className="mt-1"
                name="to"
                onChange={handleChangePrice}
                label="Gía đến"
                type="number"
                value={priceRange.to}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </div>
          </AccordionDetails>
        </Accordion>

        <Button onClick={(e) => handleClick()} className="btn-filter">
          Lọc
        </Button>
      </div>
    </div>
  );
};

export default React.memo(FilterBarVer);

import { Blog } from "features/blog/interfaces/blog.interface";
import { ResponseFilter } from "../responses/base";
import requests from "./base.service";

const DomainBlogServices = process.env.REACT_APP_BLOG_URL;

const blogServices = {
    getFilter: (query: string): Promise<ResponseFilter<Blog[]>> => {
        const url = `${DomainBlogServices}/Blogs`
        return requests.get(`${url}?${query}`)
    },
    getById(id: string): Promise<Blog> {
        const url = `${DomainBlogServices}/Blogs`
        return requests.get(`${url}/${id}`)
    }
}

export default blogServices
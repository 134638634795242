import styled from 'styled-components'
import { DisplayRow } from 'styles/components/div.styled'

export const SendEmailResetPasswordStyles = styled(DisplayRow)`
    position: relative;
    
    .captcha>div>div{
      display: flex;
      justify-content: center;
    }

    .loading{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #fff;
        opacity: .6;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-spin-dot-item{
            background-color: ${({ theme }) => theme.mainColor};;
        }
    }
`
import { Tag } from "antd";
import ImageRow from "app/common/components/image-row";
import PStep from "app/common/components/pstep";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container, DisplayColumn, DisplayRow } from "styles/components/div.styled";
import { PStrong } from "styles/components/p.styled";
import s1 from "../assets/order/s1.png";
import s2 from "../assets/order/s2.png";
import s3 from "../assets/order/s3.png";
import s4 from "../assets/order/s4.png";
import s5 from "../assets/order/s5.png";
import s6 from "../assets/order/s6.png";
import s7 from "../assets/order/s7.png";

const OrderSP = () => {
  return (
    <OrderSPStyles>
      <Container>
        <h1>Hướng dẫn thanh toán đơn hàng</h1>
        <p>
          Xin chào các bạn. Chúng mình đến từ TuHoc3DOnline team. Sau đây là hướng dẫn thanh toán
          hóa đơn các bạn đã đặt.
        </p>
        <Tag className="mt-1" color="warning">Chú ý : Các bạn phải đăng nhập trước khi thanh toán</Tag>
        <PStep className="mt-1" step="1">
          <DisplayRow>
            <div>
              {" "}
              Các bạn truy cập vào giỏ hàng :{" "}
              <Link target="_blank" rel="noopener noreferrer" className="link" to="/giohang">
                Giỏ hàng
              </Link>{" "}
              và nhấn nút "Tiến hành thanh toán".
            </div>
            <ImageRow src={s1} />
          </DisplayRow>
        </PStep>
        <PStep className="mt-1" step="2">
          <DisplayRow>
            <div>Các bạn điền các thông tin cần thiết và bấm nút "Tiến hành thanh toán".</div>
            <ImageRow
              src={s2}
              des={
                <DisplayColumn>
                  <PStrong>Lưu ý : </PStrong>
                  <p className="ml-1">
                    Hệ thống sẽ dựa vào Email điền trong form để gửi mã thanh toán. Vì vậy bạn vui
                    lòng nhập đúng Email của bạn đang sử dụng.
                  </p>
                </DisplayColumn>
              }
            />
          </DisplayRow>
        </PStep>
        <PStep className="mt-1" step="3">
          <DisplayRow>
            <div>
              Sau đó các bạn sẽ thấy các thông tin về hóa đơn và chi tiết các bước thanh toán qua
              ngân hàng. Các bạn đọc kỹ và làm theo các bước thanh toán.
            </div>
            <ImageRow src={s3} />
          </DisplayRow>
        </PStep>
        <PStep className="mt-1" step="4">
          <DisplayRow>
            <div>
              Sau khi thanh toán thành công , Hệ thống sẽ gửi email chứa key kích hoạt qua mail cho
              các bạn.
            </div>
            <ImageRow src={s6} />
          </DisplayRow>
        </PStep>
        <PStep className="mt-1" step="5">
          <DisplayRow>
            <div>
              Sau khi có mã kích hoạt các bạn vào menu phía trên bên phải. Phần "Kích hoạt khóa
              học".
            </div>
            <ImageRow src={s4} />
          </DisplayRow>
        </PStep>
        <PStep className="mt-1" step="6">
          <DisplayRow>
            <div>
              Các bạn điền key ( trong mail ) vào "Nhập mã kích hoạt tại đây" và nhấn nút kích hoạt.
            </div>
            <ImageRow src={s5} />
          </DisplayRow>
        </PStep>
        <PStep className="mt-1" step="7">
          <DisplayRow>
            <div>Khi nhập đúng mã kích hoạt thì hóa đơn sẽ được thanh toán như hình bên dưới.</div>
            <ImageRow src={s7} />
          </DisplayRow>
        </PStep>
        <p className="mt-2">
          {" "}
          Sau khi thanh toán các bạn có thể học ngay{" "}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            to="/taikhoan/trangcanhan/khoahoc"
          >
            Tại đây
          </Link>{" "}
        </p>
      </Container>
    </OrderSPStyles>
  );
};

const OrderSPStyles = styled.div`
  padding: 0 8%;
  .link {
    font-weight: 900;
    color: #ffc000 !important;
  }
`;

export default OrderSP;

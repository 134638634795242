import { Button } from "antd";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { selectBlogQuery, selectBlogs, setBlogQuery, setBlogs } from "features/blog/blogSlice";
import BlogItem from "features/blog/components/blog-item";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { stringify } from "query-string";
import blogServices from "app/api/services/blog.service";
import { BlogQuery } from "features/blog/models/query.model";

interface Props {
  isPage?: boolean;
}

const Blogs = ({ isPage = true }: Props) => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(selectBlogs);

  const query = useAppSelector(selectBlogQuery);

  const [hideLoadMore, sethideLoadMore] = useState(false);

  useEffect(() => {
    if (data.length === 0) {
      blogServices.getFilter(stringify(query)).then((res) => {
        dispatch(setBlogs(res.data));
        if (res.data.length < 3) {
          sethideLoadMore(true);
        }
      });
    }
  }, []);

  const handleAddMore = () => {
    const newQuery = new BlogQuery(query);
    if (newQuery.Page) newQuery.Page += 1;

    blogServices.getFilter(stringify(newQuery)).then((res) => {
      const newBlogs = [...data].concat(res.data);
      dispatch(setBlogs(newBlogs));
      dispatch(setBlogQuery(newQuery));
      if (res.data.length === 0 || res.data.length < 3) {
        sethideLoadMore(true);
      }
    });
  };

  if (!isPage)
    return (
      <BlogsStyles>
        {data.map((value) => (
          <BlogItem data={value} />
        ))}

        {!hideLoadMore && data.length > 0 && (
          <div className="btn-more">
            <Button onClick={handleAddMore}>Xem Thêm...</Button>
          </div>
        )}
      </BlogsStyles>
    );

  return (
    <BlogsPageStyles>
      <div>
        <h2>Blog chia sẻ</h2>
      </div>
      <div className="wrap-content">
        <BlogsStyles>
          {data.map((value) => (
            <BlogItem data={value} />
          ))}

          {!hideLoadMore && data.length > 0 && (
            <div className="btn-more">
              <Button onClick={handleAddMore}>Xem Thêm...</Button>
            </div>
          )}
        </BlogsStyles>
      </div>
    </BlogsPageStyles>
  );
};

const BlogsStyles = styled.div`
  display: flex;
  flex-direction: column;
  .btn-more {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const BlogsPageStyles = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
  }
  h2 {
    margin-left: 28px;
    padding: 4px 10px;
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 23px;
    color: #ffc000;
    font-weight: 900 !important;
  }

  .wrap-content {
    padding: 0 17%;
  }
`;

export default Blogs;

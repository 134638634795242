import { ICoursePayment } from "app/@types/course";
import paymentAPI from "app/api/services/order.service";
import CardCourseHori from "app/common/components/cardcoursehori";
import { useAppSelector } from "app/store/hooks";
import { selectUserId } from "features/account/userProfileSlice";
import React, { Fragment, useEffect, useState } from "react";
import "./styles.scss";

const CustomerCourse = () => {
  const userId = useAppSelector(selectUserId);
  const [listCourses, setListCourses] = useState<ICoursePayment[] | undefined>();

  useEffect(() => {
    if (userId)
      paymentAPI.getAllCourseHasPayment(userId).then((res) => {
        setListCourses(res.data);
      });
  }, []);

  return (
    <div className="my-cou row">
      <div>
        <h2>Danh sách khóa học đã mua</h2>
      </div>
      {!listCourses ? (
        <Fragment>
          <CardCourseHori />
          <CardCourseHori />
          <CardCourseHori />
        </Fragment>
      ) : (
        listCourses.map((value, id) => <CardCourseHori key={id} fastCourse={value} />)
      )}
    </div>
  );
};

export default CustomerCourse;

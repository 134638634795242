import { Grid } from "@material-ui/core";
import { useAppSelector } from "app/store/hooks";
import { selectCarts } from "features/cart/cartSlice";
import React from "react";
import CartDetailItem from "./CartDetailItem";

const CartDetail = () => {
  const carts = useAppSelector(selectCarts);

  return (
    <div className="row cart-detail">
      <h3>Giỏ hàng ({carts.length})</h3>
      <Grid container spacing={3} className="mt-1">
        <Grid item xs={3} className="wrap-header">
          SẢN PHẨM
        </Grid>
        <Grid item xs={6} className="wrap-header"></Grid>
        <Grid item xs={3} className="wrap-header">
          GIÁ
        </Grid>
        {carts.map((item, index) => (
          <CartDetailItem data={item} key={index} index={index} />
        ))}
      </Grid>
    </div>
  );
};

export default CartDetail;

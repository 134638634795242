import { ICategory, ICategoryDetail } from "app/@types/category";
import { IGetFilterResponse, IGetResponse } from "../responses/base";
import requests from "./base.service";

const COURSE_URL = process.env.REACT_APP_COURSE_URL as string;
const endPoint = `${COURSE_URL}categories`;

const categoryAPI = {
  getAll: (): Promise<IGetResponse<ICategory[]>> => requests.get(endPoint),

  getDetailWithCourses: (
    id: string,
    query: string = ""
  ): Promise<IGetFilterResponse<ICategoryDetail>> =>
    requests.get(`${endPoint}/${id}?${query}`),
};

export default categoryAPI;

import { TextField } from "@material-ui/core";
import React from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";

interface IProps {
  label: string;
  control: Control<Record<string, any>>;
  errors: DeepMap<Record<string, any>, FieldError>;
  name: string;
  classes?: string;
  disabled?: boolean;
  isNumber?: boolean;
}

const TextInput: React.FC<IProps> = ({
  label,
  control,
  errors,
  name,
  classes = "",
  disabled = false,
  isNumber = false,
}) => {
  return (
    <div>
      <Controller
        as={TextField}
        name={name}
        inputProps={{
          form: {
            autocomplete: "off",
          },
        }}
        type={isNumber ? "number" : "text"}
        control={control}
        disabled={disabled}
        label={label}
        error={errors[name] != null}
        className={classes}
        defaultValue=""
        variant="outlined"
        helperText={errors[name] ? errors[name].message : ""}
      />
    </div>
  );
};

export default TextInput;

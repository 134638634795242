import React, { useState } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface IProps {
  label: string;
  control: Control<Record<string, any>>;
  errors: DeepMap<Record<string, any>, FieldError>;
  name: string;
  classes?: string;
}

const PasswordInput: React.FC<IProps> = ({ label, control, errors, name, classes = "" }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <div>
      <Controller
        render={({ onChange, ...props }) => (
          <TextField
            {...props}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            label={label}
            value={props.value}
            className={classes}
            onChange={(e: any) => {
              onChange(e.target.value);
            }}
            error={errors[name] != null}
            defaultValue=""
            helperText={errors[name] ? errors[name].message : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        name={name}
        control={control}
      />
    </div>
  );
};

export default PasswordInput;

import LoadCircle from "app/common/loading/LoadCircle";
import { useAppSelector } from "app/store/hooks";
import {
  selectAppLoaded,
  selectIsLoaded,
} from "features/account/userProfileSlice";
import React from "react";
import {
  RouteProps,
  RouteComponentProps,
  Route,
  Redirect,
} from "react-router-dom";

interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
}

const PrivateRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
  const appLoaded = useAppSelector(selectAppLoaded);

  const isLoggedIn = useAppSelector(selectIsLoaded);

  if (!appLoaded)
    return (
      <div className="wrap-lc">
        <LoadCircle />
      </div>
    );

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to="/taikhoan/dangnhap" />
        )
      }
    />
  );
};

export default PrivateRoute;

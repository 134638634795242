const theme = {
    mainColor: '#FFC000',
    responsive: {
        mobile: '380px',
        mobileLarge: '430px',
        tablet: '756px',
        desktop: '1366px',
        desktopSmall: '990px',
        home: '825px',
        tabletSmall: '510px',
        tabletMid: '642px',
        tableBig: '867px'
    }
} as const

export type ThemeStyles = typeof theme

declare module "styled-components" {
    export interface DefaultTheme extends ThemeStyles {

    }
}


export default theme;
import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import "./styles.scss";

interface IProp {
  content: string | null;
}

const CardBenifitDescription: React.FC<IProp> = ({ content }) => {
  return (
    <div className="card-ben-des col">
      <div className="icon-star d-f center">
        <AiFillCheckCircle />
      </div>
      <div className="des">{content}</div>
    </div>
  );
};

export default CardBenifitDescription;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "app/common/form/textinput";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { selectUserId } from "features/account/userProfileSlice";
import { CARTS_NAME, resetCart, selectCourseDetailsToPayment } from "features/cart/cartSlice";
import { OrderInsertSuccess } from "app/@types/payment";
import paymentAPI from "app/api/services/order.service";
import { showError, showMessage } from "app/notification";
import ReCAPTCHA from "react-google-recaptcha";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const userInfomationPaymentSchema = yup.object().shape({
  firstName: yup.string().required("Họ bắt buộc"),
  lastName: yup.string().required("Tên bắt buộc"),
  phoneNumber: yup
    .string()
    .required("Số điện thoại bắt buộc")
    .matches(phoneRegExp, "Số điện thoại không hợp lệ"),
  email: yup.string().required("Email bắt buộc").email("Email không hợp lệ"),
  city: yup.string().required("Thành phố bắt buộc"),
  district: yup.string().required("Quận/Huyện bắt buộc"),
  address: yup.string().required("Địa chỉ bắt buộc"),
});

type props = {
  setOrderBill: React.Dispatch<React.SetStateAction<OrderInsertSuccess | undefined>>;
  setOrderDesDetail: React.Dispatch<React.SetStateAction<string>>;
};

const ProfileVerifyForm = ({ setOrderBill, setOrderDesDetail }: props) => {
  const userId = useAppSelector(selectUserId);

  const [captcha, setCaptcha] = useState<string | null>("");

  const orderDetails = useAppSelector(selectCourseDetailsToPayment);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(userInfomationPaymentSchema),
  });

  const dispatch = useAppDispatch();

  const onCaptchaChange = (data: string | null) => {
    setCaptcha(data);
  };

  const onSubmit = async (data: any) => {
    if (!captcha) {
      showError("Vui lòng thực hiện xác thực Captcha");
      return;
    }
    if (userId) {
      try {
        const res = await paymentAPI.createOrder({
          userId,
          orderDetails,
          userInfomationPayment: data,
        });
        showMessage("Tạo hóa đơn thành công");
        dispatch(resetCart());
        window.localStorage.removeItem(CARTS_NAME);
        setOrderDesDetail(orderDetails.map((x) => x.courseName).join(", "));
        setOrderBill(res.data);
      } catch (err: any) {
        showError(err.error);
      }
    }
  };

  return (
    <div className="row prof-ver-form">
      <div className="wrap-form row">
        <h3>Thông tin thanh toán</h3>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-1">
          <TextInput
            classes="w-100"
            label="Họ"
            control={control}
            errors={errors}
            name="firstName"
          />
          <TextInput
            classes="mt-2 w-100"
            label="Tên"
            control={control}
            errors={errors}
            name="lastName"
          />
          <TextInput
            classes="mt-2 w-100"
            label="Số Điện Thoại"
            control={control}
            errors={errors}
            name="phoneNumber"
          />
          <TextInput
            classes="mt-2 w-100"
            label="Email"
            control={control}
            errors={errors}
            name="email"
          />
          <TextInput
            classes="mt-2 w-100"
            label="Thành Phố"
            control={control}
            errors={errors}
            name="city"
          />
          <TextInput
            classes="mt-2 w-100"
            label="Quận/Huyện"
            control={control}
            errors={errors}
            name="district"
          />
          <TextInput
            classes="mt-2 w-100"
            label="Địa Chỉ"
            control={control}
            errors={errors}
            name="address"
          />

          <div className="mt-1">
            <ReCAPTCHA
              sitekey="6Lc1TgUeAAAAANwQ0OH4nBNFgoDtkc_woxF2es4r"
              onChange={onCaptchaChange}
            />
          </div>

          <div className="col">
            <button className="ml-auto btn-buy" type="submit" disabled={orderDetails.length < 1}>
              Tiến hành thanh toán
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileVerifyForm;

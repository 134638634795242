import { Paper } from "@material-ui/core";
import { Descriptions } from "antd";
import { ICourseStudyDetail, ILecture } from "app/@types/course";
import courseAPI from "app/api/services/course.service";
import LoadFb from "app/common/loading/LoadFb";
import LoadOpacity from "app/common/loading/LoadOpacity";
import { useAppSelector } from "app/store/hooks";
import { selectUserId } from "features/account/userProfileSlice";
import CardSection from "features/course/components/cardsection";
import { history } from "index";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import "./styles.scss";

interface IParams {
  courseId: string;
}

const LoadingSection = () => {
  return (
    <div className="card-videos-loading row">
      <LoadFb width="19%" height="20px" radius="3px" />
      <LoadOpacity />
      <LoadOpacity />
      <LoadOpacity />
      <LoadOpacity />
      <LoadOpacity />
    </div>
  );
};

const CourseStudy = () => {
  const params = useParams<IParams>();

  const [listCSD, setListCSD] = useState<ICourseStudyDetail | undefined>();

  const userId = useAppSelector(selectUserId);

  const [sourceVideo, setSourceVideo] = useState("");

  const [lectureName, setLectureName] = useState("");

  const [decs, setDecs] = useState("");

  const [currentSeen, setCurrentSeen] = useState("");

  useEffect(() => {
    if (userId)
      courseAPI
        .getCourseForStudy({
          userId,
          courseId: params.courseId,
        })
        .then((res) => {
          setListCSD(res.data);
          setSourceVideo(handleGetVideoLink(res.data));
          setLectureName(res.data.sections[0].lectures[0].name || "");
          setDecs(res.data.sections[0].lectures[0].description || "");
          setCurrentSeen(res.data.lastedSeenLectue || "");
        })
        .catch((err) => {
          history.push("/");
        });
  }, [userId, courseAPI]);

  const handleGetVideoLink = (data: ICourseStudyDetail) => {
    let videoLink = "";
    if (data) {
      if (data.lastedSeenLectue) {
        data.sections.map((sec) => {
          sec.lectures.map((lec) => {
            if (lec.id === data.lastedSeenLectue) {
              videoLink = lec.video as any;
            }
          });
        });
      } else {
        videoLink = _.sortBy(data.sections[0].lectures, ["index"])[0].video as any;
      }
    }
    return videoLink;
  };

  const setSelectLecture = (data: ILecture) => {
    setSourceVideo(data.video || "");
    setLectureName(data.name || "");
    setDecs(data.description || "");
    setCurrentSeen(data.id);
  };

  return (
    <CourseWrapper>
      <div className="wrap-video">
        <div className="row">
          <ReactPlayer
            width="100%"
            height="100%"
            playing={true}
            controls
            onContextMenu={(e: any) => e.preventDefault()}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
            url={sourceVideo}
          />
          <h2 className="mt-2">{lectureName}</h2>
          {decs && (
            <div className="des bg-white note">
              <Descriptions className="mt-2" title="Ghi Chú Bài Học">
                <Descriptions.Item label="">
                  <div
                    className="bg-white mt-0"
                    dangerouslySetInnerHTML={{
                      __html: decs.replaceAll("<a ", "<a target='_blank' "),
                    }}
                  ></div>
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </div>
      </div>
      <div className="wrap-sec-details">
        {!listCSD ? (
          <Paper className="p-1">
            <LoadingSection />
          </Paper>
        ) : (
          <Paper className="p-1" elevation={3}>
            <h2>{listCSD.name}</h2>
            {_.sortBy(listCSD.sections, ["index"]).map((sc, id) => (
              <CardSection
                lectureCurrentSeen={currentSeen}
                setSelectLecture={setSelectLecture}
                key={id}
                data={sc}
              />
            ))}
          </Paper>
        )}
      </div>
    </CourseWrapper>
  );
};

export default CourseStudy;

const CourseWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 150px !important;
  display: flex;
  flex-direction: row;

  @media (max-width: ${({ theme }) => theme.responsive.tablet}) {
    flex-direction: column !important;

    .wrap-video {
      width: 100% !important;
      margin: 0;
      min-height: 160px !important;
      .row {
        padding: 16px;
      }
    }
    .wrap-sec-details {
      width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      .MuiPaper-elevation3 {
        margin: 1rem !important;
      }
    }
  }

  .wrap-video {
    width: 70%;
    position: relative;
    .row {
      padding: 16px;
    }
    video {
      width: 100% !important;
      height: 100%;
    }
  }

  .wrap-sec-details {
    margin: 0 20px;
    width: 30%;
    padding: 1rem;
    height: 100%;
  }
`;

import { useAppDispatch } from "app/store/hooks";
import CardDescriptionVideo from "features/course/components/carddesvideocourse";
import CardContent from "features/course/components/cardinfo";
import { fetchCourseDetail } from "features/course/courseSlice";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

interface IParams {
  courseId: string;
}

const DetailCourse = () => {
  const params = useParams<IParams>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCourseDetail(params.courseId));
  }, []);

  return (
    <DetailCourseWapper className="col container-10">
      <CardContent />
      <div className="card-des-video">
        <CardDescriptionVideo />
      </div>
    </DetailCourseWapper>
  );
};

export default DetailCourse;

const DetailCourseWapper = styled.div`
  min-height: 140px;

  @media (max-width: ${({ theme }) => theme.responsive.desktopSmall}) {
    padding: 0 12px !important;

    &.col {
      flex-direction: column-reverse !important;
    }
    .card-des-video {
      width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      padding-top: 10px !important;
      margin: 0 12px !important;
      .w-100 {
        width: 95% !important ;
      }
    }

    .card-ct {
      width: 100% !important;
    }

    .card-ben {
      &-des {
        width: 100% !important;
      }
      img {
        display: none !important;
      }
    }
  }
  .card-ct {
    width: 70%;
  }

  .card-des-video {
    width: 30%;
  }

  .card-frame {
    margin: 10px;
    padding: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.175) 0px 3px 8px;
    border-radius: 8px;
  }
`;

import React from "react";
import "./styles.scss";

interface IProps {
  title: string;
}

const CoursesNew: React.FC<IProps> = ({ title, children }) => {
  return (
    <div className="new-courses row">
      <h2>{title}</h2>
      {children}
    </div>
  );
};

export default CoursesNew;

import React, { FC } from "react";
import styled from "styled-components";
import { PStrong } from "styles/components/p.styled";

interface PStepProps {
  step: string;
  content?: any;
  className?: string;
}

const PStep: FC<PStepProps> = ({ step, content, children, className = "" }) => {
  return (
    <PStepStyles className={className}>
      <PStrong>Bước {step} :</PStrong>
      {children ? children : <p className="content">{content}</p>}
    </PStepStyles>
  );
};

const PStepStyles = styled.div`
  .content::before {
    content: " ";
  }
`;

export default PStep;

import React, { Fragment, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { RiArrowDropDownLine } from "react-icons/ri";
import { BsList } from "react-icons/bs";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import LoadFb from "app/common/loading/LoadFb";
import Drawer from "@material-ui/core/Drawer";
import { RiArrowRightSLine } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { fetchCategories, selectCategories } from "features/nav/NavbarSlice";
import ProfileMenu from "features/account/components/menu";

const CategoryLoading = () => {
  return (
    <div className="row p-1">
      <LoadFb height={15} width="100%" />
      <LoadFb height={15} width="100%" />
      <LoadFb height={15} width="100%" />
      <LoadFb height={15} width="100%" />
      <LoadFb height={15} width="100%" />
      <LoadFb height={15} width="100%" />
      <LoadFb height={15} width="100%" />
    </div>
  );
};

const CategoryList = () => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const prevOpen = useRef(open);

  const [loading, setLoading] = useState(true);

  const [openAnchor, setOpenAnchor] = useState(false);

  const categoryList = useAppSelector(selectCategories);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const dispath = useAppDispatch();

  useEffect(() => {
    dispath(fetchCategories()).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <Fragment>
      <Drawer anchor="left" open={openAnchor} onClose={() => setOpenAnchor(false)}>
        <ProfileMenu onClick={() => setOpenAnchor(false)} otherCourses />
      </Drawer>
      <div className="wrap-cate">
        <Button
          className="cate-list"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <div className="col">
            <p>Khóa học</p>
            <RiArrowDropDownLine />
          </div>
        </Button>
        <BsList className="icon" onClick={() => setOpenAnchor(true)} />
      </div>
      <Popper
        className="wrap-mnu"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {loading ? (
                    <CategoryLoading />
                  ) : (
                    categoryList.map((c) => (
                      <Link to={`/khoahoc/chude/${c.id}`}>
                        <MenuItem onClick={handleClose} key={c.id}>
                          <div className="col">
                            {c.name}
                            <RiArrowRightSLine />
                          </div>
                        </MenuItem>
                      </Link>
                    ))
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default React.memo(CategoryList);

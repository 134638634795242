import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICourseDetail } from "app/@types/course";
import courseAPI from "app/api/services/course.service";
import { AppDispatch, RootState } from "app/store";
import { history } from "index";
import { ICourseState } from "./@types";

const initialState: ICourseState = {
  isLoadingDetail: true,
};

export const fetchCourseDetail =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      const res = await courseAPI.getById(id);
      dispatch(setCourseDetail(res.data));
    } catch (error) {
      const { status } = error;
      if (status === 400) history.push("/khongtimthay");
    }
  };

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setCourseDetail(state, { payload }: PayloadAction<ICourseDetail>) {
      state.courseDetail = payload;
      state.isLoadingDetail = false;
    },
  },
});

const { actions, reducer } = courseSlice;

export const { setCourseDetail } = actions;

export default reducer;

export const selectIsLoadingDetail = (state:RootState) => state.course.isLoadingDetail;

export const selectCourseDetail = (state:RootState) => state.course.courseDetail;

import { css } from "styled-components";

const loadingCss = css`
  animation-name: loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  background-color: #e2e1e1;
  @keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.45;
  }
  100% {
    opacity: 1;
  }
}
`

export default loadingCss;
import { useAppSelector } from "app/store/hooks";
import { selectUserProfile } from "features/account/userProfileSlice";
import { selectCarts } from "features/cart/cartSlice";
import React from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

const CartDetailPrice = () => {
  const carts = useAppSelector(selectCarts);

  const user = useAppSelector(selectUserProfile);

  const totalPrice = Math.ceil(
    carts.reduce((prev, current) => {
      return prev + current.price * (1 - current.percent);
    }, 0)
  );

  return (
    <div className="row cart-detail-price">
      <h3>Thông tin đơn hàng</h3>
      <div className="col mt-1">
        <p>Tạm tính</p>
        <p className="price-term">
          <NumberFormat
            value={totalPrice}
            displayType={"text"}
            thousandSeparator={true}
          />
          {' '}đ
        </p>
      </div>
      <div className="col line-top">
        <p>Tổng tiền cần thanh toán</p>
        <p className="price-final">
          <NumberFormat
            value={totalPrice}
            displayType={"text"}
            thousandSeparator={true}
          />
          {' '}đ
        </p>
      </div>
      {user ? (
        carts.length > 0 ? (
          <Link to="/giohang/thanhtoan" className="btn-buy mt-1">
            Tiến hành thanh toán
          </Link>
        ) : (
          <Link to="/khoahoc/timkiem" className="btn-buy mt-2">
            Thêm khóa học vào giỏ
          </Link>
        )
      ) : (
        <Link to="/taikhoan/dangnhap" className="btn-buy mt-2">
          Đăng nhập để thanh toán
        </Link>
      )}
    </div>
  );
};

export default CartDetailPrice;

import PrivateRoute from "app/routers/PrivateRoute";
import React from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import CartView from "./pages/CartView/CartView";
import ProfileVerify from "./pages/ProfileVerifyForm/ProfileVerify";

const CartPage: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}`} exact component={CartView} />
      <PrivateRoute path={`${match.url}/thanhtoan`} exact component={ProfileVerify} />
    </Switch>
  );
};

export default withRouter(CartPage);

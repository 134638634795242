import useWindowSize from "hooks/window.hook";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { Image } from "antd";

export type slideType = {
  srcImage: string;
};

export type slideProps = {
  slides: slideType[];
  classes?: string;
  normal?: boolean;
};

const Slide = ({ slides, classes, normal }: slideProps) => {
  return (
    <div className="slide">
      {slides.map((sl, id) => (
        <div key={id} className={"wrap-content " + classes}>
          <div className="wrap-img">
            {normal ? <img src={sl.srcImage} alt="img" /> : <Image src={sl.srcImage} alt="img" />}
          </div>
        </div>
      ))}
    </div>
  );
};

export type slidesProps = {
  slides: slideProps[];
  classes?: string;
  normal?: boolean;
};

const Slides = ({ slides, classes = "", normal = false }: slidesProps) => {
  const size = useWindowSize();

  if (size[0] < 620) {
    const slidesFlatten: any = [];

    slides.forEach((sl) => {
      sl.slides.forEach((subSl) => {
        slidesFlatten.push(subSl);
      });
    });

    return (
      <Carousel className="mySlides my-2" indicators autoPlay={false} animation="slide">
        {slidesFlatten &&
          slidesFlatten.map((slide: any, id: number) => (
            <Slide key={id} normal={normal} classes={classes} slides={[slide]} />
          ))}
      </Carousel>
    );
  }

  return (
    <Carousel className="mySlides my-2" indicators autoPlay={false} animation="slide">
      {slides.map((slide, id) => (
        <Slide key={id} normal={normal} classes={classes} slides={slide.slides} />
      ))}
    </Carousel>
  );
};

export default React.memo(Slides);

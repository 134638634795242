import React, { useState } from "react";
import styled from "styled-components";
import { VscSearch } from "react-icons/vsc";
import { history } from "index";
import { parse, stringify } from "query-string";

const SearchingMobile = () => {
  const [key, setKey] = useState("");

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const queryObj = parse(history.location.search);
      queryObj.name = key;
      history.push(`/khoahoc/timkiem?${stringify(queryObj)}`);
    }
  };

  return (
    <SearchWrapper>
      <div className="wrap-search">
        <input
          value={key}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => setKey(e.target.value || "")}
          placeholder="Nhập từ khóa cần tìm ..."
        />
        <VscSearch className="icon" />
      </div>
    </SearchWrapper>
  );
};

export default SearchingMobile;

const SearchWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;



  @media (min-width: ${({ theme }) => theme.responsive.tablet}) {
    display: none !important;
  }

  .wrap-search {
    min-width: 80%;
    border: 0.3px solid #c2c2c2;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      width: 89%;
      border: none !important;
      border-radius: 4px;
      padding: 8px 5px;
    }

    .icon {
      width: 9%;
    }
  }
`;

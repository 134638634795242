import styled from "styled-components";

export const Container = styled.div`
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    min-height: 60vh;
`
export const DisplayRow = styled.div`
    display: flex;
    flex-direction: column;
`

export const DisplayColumn = styled.div`
    display: flex;
    flex-direction: row;
`
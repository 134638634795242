import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { parse, stringify } from "query-string";
import styled from "styled-components";
import { history } from "index";

const WrapInput = styled.div`
  width: 30%;
  height: 40px;
  margin-right: auto;
  position: relative;

  @media (max-width: ${({ theme }) => theme.responsive.home}) {
    display: none;
  }

  .input-search {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    border: 0px;
    background-color: #f2f3f4;
    padding: 2px 15px;
    font-weight: normal !important;
    font-weight: 800;
    &:focus {
      box-shadow: 0 0 0.5pt 0.6pt #ffc000;
    }
  }

  svg {
    position: absolute;
    top: 10px;
    font-size: 130%;
    right: 10px;
    color: #ffc000;
  }
`;

const SearchBar = () => {
  const [searchKey, setsearchKey] = useState<string>("");

  const handleChange = (value: string) => {
    setsearchKey(value);
  };

  const handleKeyDown = (key: string) => {
    if (key === "Enter") {
      if (history.location.pathname.includes("chude")) {
        let pasred: any = parse(history.location.search);
        pasred.name = searchKey;
        history.push(`${history.location.pathname}?${stringify(pasred)}`);
      } else {
        history.push(`/khoahoc/timkiem?name=${searchKey}`);
      }
    }
  };

  return (
    <WrapInput>
      <input
        className="input-search"
        value={searchKey}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e.key)}
        placeholder="Tìm kiếm ..."
      ></input>
      <AiOutlineSearch />
    </WrapInput>
  );
};

export default SearchBar;

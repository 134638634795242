import React from "react";
import { Route, RouteComponentProps, withRouter, Switch } from "react-router-dom";
import BlogDetail from "./pages/detail";
import Blogs from "./pages/list";

const Blog = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={BlogDetail} />
      <Route exact path={`${match.url}`} component={Blogs} />
    </Switch>
  );
};

export default withRouter(Blog);

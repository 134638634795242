import React from "react";
import { BiCheckCircle } from "react-icons/bi";
import "./styles.scss";

interface IProps {
  content: string;
}

const CardRequireItem: React.FC<IProps> = ({ content }) => {
  return (
    <div className="card-req-item col">
      <div className="icon d-f center">
        <BiCheckCircle />
      </div>
      <div className="content">{content}</div>
    </div>
  );
};

export default CardRequireItem;

import React from "react";
import "./styles.scss";
import LoginForm from "features/account/components/login";
import Registry from "features/account/components/regis";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useAppSelector } from "app/store/hooks";
import { selectIsLoaded } from "features/account/userProfileSlice";
import { fp, mk } from "app/constants/images";
import ResetPassword from "features/account/components/resetpassword";

const Main = () => {
  const match = useRouteMatch();

  const isLoaded = useAppSelector(selectIsLoaded);

  if (isLoaded) return <Redirect to="/taikhoan/trangcanhan" />;

  return (
    <div className="main-acc">
      <div className="container-15 col">
        <div className="bg w-50 d-f center">
          <Switch>
            <Route path={`${match.url}/datlaimk`} component={() => <img src={fp} alt="bglg" />}  />
            <Route component={() => <img src={mk} alt="bglg" />} />
          </Switch>
        </div>
        <div className="w-50 d-f center content">
          <Switch>
            <Route path={`${match.url}/dangnhap`} exact component={LoginForm} />
            <Route path={`${match.url}/dangky`} exact component={Registry} />
            <Route path={`${match.url}/datlaimk`} exact component={ResetPassword} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Main;

import { Row, Col, Tag } from "antd";
import { Blog } from "features/blog/interfaces/blog.interface";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  data: Blog;
}

const extractContent = (s: any, space: any = true, num: any = 76) => {
  if (!s) return "";
  const span = document.createElement("span");
  span.innerHTML = s;
  if (space) {
    const children = span.querySelectorAll("*");
    for (var i = 0; i < children.length; i++) {
      if (children[i].textContent) children[i].textContent += " ";
      else (children[i] as any).innerText += " ";
    }
  }
  const text = [span.textContent || span.innerText]
    .toString()
    .replace(/ +/g, " ")
    .replaceAll("<br>", "")
    .replaceAll("\n", "");

  let textArr = text.split(" ").filter((x) => x !== " ");

  textArr = textArr.splice(0, num);

  return textArr.join(" ") + " ...";
};

const BlogItem = ({ data }: Props) => {
  return (
    <BlogItemStyles>
      <Link to={`/blog/${data.id}`}>
        <Row>
          <Col span={5}>
            <div className="wrap-img">
              <img src={data.avatar} alt="pic" />
            </div>
          </Col>
          <Col className="content" span={19}>
            <div className="title">{data.title}</div>
            <div className="time">
              <Tag color="#FFC000">Ngày đăng : {moment(data.date).format("DD/MM/yyyy")}</Tag>
            </div>
            <div className="auth mt-1">
              <Tag color="orange">Tác giả : Tự học 3D</Tag>
            </div>
            <div className="des">{extractContent(data.content)}</div>
          </Col>
        </Row>
      </Link>
    </BlogItemStyles>
  );
};

const BlogItemStyles = styled.div`
  margin-top: 14px;
  padding: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  background-color: white;

  &:hover {
    img {
      transform: scale(1.2);
    }
  }

  .wrap-img {
    width: 100%;
    overflow: hidden;
    height: 100%;
    img {
      width: 100%;
      transition: transform 0.8s;
      height: 100%;
      object-fit: contain;
    }
  }

  .content {
    padding: 0 20px;

    .title {
      font-size: 20px !important;
      font-weight: 700;
    }
  }

  .des {
    margin-top: 10px;
    font-size: 12px;
    font-style: italic;
    color: #666666;
  }
`;

export default BlogItem;

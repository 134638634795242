import React, { useEffect, useRef, useState, MouseEvent } from "react";
import "./styles.scss";
import CategoryList from "./components/category/CategoryList";
import SearchBar from "./components/searchinput/SearchBar";
import Cart from "./components/cart";
import ButtonGroup from "./components/buttongroup";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { logout, selectUserProfile } from "features/account/userProfileSlice";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { HiUserCircle } from "react-icons/hi";
import { fetchCourseByIds } from "features/cart/cartSlice";
import { history } from "index";
import SearchingMobile from "./components/searchmobile";
import { logoHeader } from "app/constants/images";

const Navigation = () => {
  const user = useAppSelector(selectUserProfile);

  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const prevOpen = useRef(open);

  const isHome = history.location.pathname === "/";

  const dispatch = useAppDispatch();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown: any = (event: KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleClick = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(fetchCourseByIds());

    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className="wrap-nav">
      <header className={`nav ${isHome && "nav-home"}`}>
        <div className="container col">
          <Link to="/">
            <div className="wrap-img">
              <img src={logoHeader} alt="logoHeader" />
            </div>
          </Link>

          <CategoryList />

          <Link to="/blog" className="blog-link">
            Blogs
          </Link>

          <Link to="/" className="wrap-img-mobile">
            <img src={logoHeader} alt="logoHeader" />
          </Link>
          <SearchBar />

          <Cart />
          {user ? (
            <div>
              <Button
                className="btn-prof"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <HiUserCircle />
                {` ${user.username}`}
              </Button>
              <Popper
                className="wrap-mnu"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper elevation={3}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleClose}>
                            <Link to="/taikhoan/trangcanhan">Trang cá nhân</Link>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <Link to="/taikhoan/trangcanhan/doimatkhau">Đổi mật khẩu</Link>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <Link to="/taikhoan/trangcanhan/thongbao">Thông báo từ khóa học</Link>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <Link to="/taikhoan/trangcanhan/khoahoc">Khóa học của tôi</Link>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <Link to="/taikhoan/trangcanhan/kichhoat">Kích hoạt khóa học</Link>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <Link to="/" onClick={(e) => handleClick()}>
                              Đăng xuất
                            </Link>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          ) : (
            <ButtonGroup />
          )}
        </div>
        <SearchingMobile />
      </header>
    </div>
  );
};

export default Navigation;

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";

export interface ITokenModel {
  accessToken: string;
  refreshToken: string;
}

export const saveToken = (token: ITokenModel) => {
  window.localStorage.setItem(ACCESS_TOKEN, token.accessToken);
  window.localStorage.setItem(REFRESH_TOKEN, token.refreshToken);
};

export const removeToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
};

export const getToken = (): ITokenModel => {
  return {
    accessToken: window.localStorage.getItem(ACCESS_TOKEN) + "",
    refreshToken: window.localStorage.getItem(REFRESH_TOKEN) + "",
  };
};

import CardCourse from "app/common/components/coursecard";
import React from "react";
import js from "app/assets/js.jpg";
import { ICourse } from "app/@types/course";
import { Link } from "react-router-dom";

interface IProps {
  data?: ICourse[];
}

const CoursesFilter5: React.FC<IProps> = ({ data }) => {
  if (!data)
    return (
      <div className="new-courses">
        <div className="courses-content col">
          <CardCourse loading={true} />
          <CardCourse loading={true} />
          <CardCourse loading={true} />
          <CardCourse loading={true} />
          <CardCourse loading={true} />
          <CardCourse loading={true} />
          <CardCourse loading={true} />
        </div>
      </div>
    );

  return (
    <div className="new-courses">
      <div className="courses-content col">
        {data.map((course) => (
          <Link to={`/khoahoc/${course.id}`}>
            <CardCourse
              percent={course.percent}
              description={course.title}
              author="Tự học 3D"
              price={course.price}
              title={course.name + ""}
              image={course.photo + "" || js}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default React.memo(CoursesFilter5);

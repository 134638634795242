import { OrderHistoryUnpay } from "app/@types/payment";
import paymentAPI from "app/api/services/order.service";
import OrderCard from "app/common/components/ordercard";
import { useAppSelector } from "app/store/hooks";
import { selectUserId } from "features/account/userProfileSlice";
import React, { useEffect, useState } from "react";
import "./styles.scss";

const ActiveCourse = () => {
  const userId = useAppSelector(selectUserId);
  const [listOrderHistory, setListOrderHistory] = useState<OrderHistoryUnpay[] | undefined>();

  const handleDeleteOrder = (index: number) => {
    if (listOrderHistory && listOrderHistory[index]) {
      let cloneHis = [...listOrderHistory];
      cloneHis.splice(index, 1);
      setListOrderHistory(cloneHis);
    }
  };

  const handleDelete = (orderId: string) => {
    setListOrderHistory(listOrderHistory?.filter((x) => x.id !== orderId));
  };

  useEffect(() => {
    if (userId) {
      paymentAPI.orderHistoryUnpay(userId).then((res) => {
        setListOrderHistory(res.data);
      });
    }
  }, [userId]);

  return (
    <div className="active-cou row">
      {!listOrderHistory && <OrderCard />}
      {listOrderHistory &&
        listOrderHistory.map((oh, id) => (
          <OrderCard
            handleDelete={handleDelete}
            handlePaymentOrder={handleDeleteOrder}
            index={id}
            key={id}
            order={oh}
          />
        ))}
    </div>
  );
};

export default ActiveCourse;

import React from "react";
import { BsStarFill, BsStar } from "react-icons/bs";
import styled from "styled-components";
import "./styles.scss";

const Feedback = () => {
  return (
    <FbWrapper className="row">
      <h2>Đánh giá từ học viên</h2>
      <div className="fee-b w-100 col">
        <div className="current-rate row">
          <div className="point">
            <div className="current">4.9</div>
          </div>
          <div className="stars d-f center">
            <BsStarFill />
            <BsStarFill />
            <BsStarFill />
            <BsStarFill />
            <BsStarFill />
          </div>
          <div className="total-rate d-f center">Đánh giá khóa học</div>
        </div>

        <div className="bar-rate">
          <div className="star col">
            <div className="bar">
              <div className="per9"></div>
            </div>
            <div className="num col">
              <BsStarFill />
              <BsStarFill />
              <BsStarFill />
              <BsStarFill />
              <BsStarFill />
            </div>
          </div>
          <div className="star col">
            <div className="bar">
              <div className="per2"></div>
            </div>
            <div className="num">
              <BsStarFill />
              <BsStarFill />
              <BsStarFill />
              <BsStarFill />
              <BsStar />
            </div>
          </div>
          <div className="star col">
            <div className="bar">
              <div className="per0"></div>
            </div>
            <div className="num">
              <BsStarFill />
              <BsStarFill />
              <BsStarFill />
              <BsStar />
              <BsStar />
            </div>
          </div>
          <div className="star col">
            <div className="bar">
              <div className="per0"></div>
            </div>
            <div className="num">
              <BsStarFill />
              <BsStarFill />
              <BsStar />
              <BsStar />
              <BsStar />
            </div>
          </div>
          <div className="star col">
            <div className="bar">
              <div className="per0"></div>
            </div>
            <div className="num">
              <BsStarFill />
              <BsStar />
              <BsStar />
              <BsStar />
              <BsStar />
            </div>
          </div>
        </div>
      </div>
    </FbWrapper>
  );
};

export default Feedback;

const FbWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.responsive.desktopSmall}) {
    .fee-b{
      flex-direction: column !important;

      .current-rate{
        margin-bottom: 12px;
        width: 100%;
      }
    }
  }
`;

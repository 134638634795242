import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const WrapBtnGroup = styled.div`
  align-items: center;
  margin-right: 2%;

  @media (max-width: ${({ theme }) => theme.responsive.mobileLarge}) {
    display: none !important;
  }

  .btn-login {
    padding: 7px 10px;
    border-radius: 4px;
    border: 1px solid #ffc000;
    color: #ffc000;
    font-weight: 600;
  }
  .btn-regis {
    margin-left: 10px;
    padding: 7px 10px;
    border-radius: 4px;
    background-color: #ffc000;
    color: white;
  }
`;

const ButtonGroup = () => {
  return (
    <WrapBtnGroup className="col">
      <Link to="/taikhoan/dangnhap" className="btn-login">
        Đăng nhập
      </Link>
      <Link to="/taikhoan/dangky" className="btn-regis">
        Đăng ký
      </Link>
    </WrapBtnGroup>
  );
};

export default ButtonGroup;

import React from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import OrderSP from "./components/OrderSP";
import RegistSP from "./components/RegistSP";

const Support: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <div className="w-100">
      <Switch>
        <Route exact path={`${match.url}/hoadon`} component={OrderSP} />
        <Route exact path={`${match.url}/dangky`} component={RegistSP} />
      </Switch>
    </div>
  );
};

export default withRouter(Support);

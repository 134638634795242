import { Button } from "@material-ui/core";
import { notification } from "antd";
import { OrderHistoryUnpay } from "app/@types/payment";
import paymentAPI from "app/api/services/order.service";
import LoadOpacity from "app/common/loading/LoadOpacity";
import { convertDataFormat } from "app/helper/convert";
import { showError, showSuccess } from "app/notification";
import { useAppSelector } from "app/store/hooks";
import { selectUserId } from "features/account/userProfileSlice";
import { history } from "index";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import OrderCourseCard from "./OrderCourseCard";

interface IProps {
  isSale?: boolean;
  index?: any;
  order?: OrderHistoryUnpay;
  handlePaymentOrder?: (index: number) => void;
  handleDelete?: any;
}

const OrderCard: React.FC<IProps> = ({
  isSale = false,
  order,
  index,
  handlePaymentOrder,
  handleDelete,
}) => {
  const [code, setCode] = useState<string>("");

  const userId = useAppSelector(selectUserId);

  if (!order)
    return (
      <div className="order-card row">
        <div className="detail row">
          <h3 style={{ marginBottom: "10px" }}>Thông tin giao hàng</h3>
          <div className="col">
            <div className=" w-50">
              <LoadOpacity width="50%" height={15} />
              <LoadOpacity width="50%" height={15} />
              <LoadOpacity width="50%" height={15} />
            </div>
            <div className=" w-50">
              <LoadOpacity width="50%" height={15} />
              <LoadOpacity width="50%" height={15} />
              <LoadOpacity width="50%" height={15} />
            </div>
          </div>
          <h3 className="mt-2">
            <div className="title">
              <LoadOpacity width="30%" height={15} />
            </div>
          </h3>
          <div className="row">
            <OrderCourseCard />
            <OrderCourseCard />
            <OrderCourseCard />
            <LoadOpacity width="10%" height={15} />
          </div>
        </div>
      </div>
    );

  const handleClick = () => {
    if (order && order.id && handlePaymentOrder) {
      paymentAPI
        .paymentByCode({
          code,
          orderId: order.id,
        })
        .then(() => {
          showSuccess("Bạn đã thanh toán thành công", 4500);
          handlePaymentOrder(index);
          history.push("/taikhoan/trangcanhan/khoahoc");
        })
        .catch((err) => {
          showError(err.error, 5000);
        });
    }
  };

  const handleDeleteOrder = async () => {
    if (userId) {
      try {
        await paymentAPI.userDeleteOrder(userId, order.id);
        notification.success({ message: "Xóa thành công" });
        if (handleDelete) handleDelete(order.id);
      } catch (error) {
        notification.error({ message: "Xóa thất bại" });
      }
    }
  };

  return (
    <OrderCardWrapper className="row">
      <div className="detail row">
        <div className="col">
          <h3>Thông tin giao hàng</h3>
          {!isSale ? (
            <div className="activate-code col ml-auto">
              <input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="nhập mã kích hoạt tại đây..."
              />
              <Button onClick={handleClick}>Kích hoạt</Button>
              <Button className="btn-delete" onClick={handleDeleteOrder}>
                Xóa hóa đơn
              </Button>
            </div>
          ) : (
            <div className="wrap-code col center">
              <div className="code-active">
                <strong>Mã kích hoạt</strong>
                {" : "}
                {order.activateKey}
              </div>
            </div>
          )}
        </div>

        <div className="col wrap-detail-date">
          <div className="wrap-date">
            <strong>Ngày đặt : </strong>
            {convertDataFormat(order.dateCreate)}
          </div>
          {order.datePayment && (
            <div className="wrap-date-active">
              <strong>Ngày thanh toán : </strong>
              {convertDataFormat(order.datePayment)}
            </div>
          )}
          {order.datePayment && (
            <div className="wrap-order-by">
              <strong>Hình Thức Thanh Toán : </strong>
              Chuyển Khoản Ngân Hàng
            </div>
          )}
        </div>

        <div className="col">
          <div className=" w-50">
            <div className="wrap-content col">
              <div className="title">Mã đơn hàng :</div>
              <div className="content">{order.publicId}</div>
            </div>
            <div className="wrap-content col">
              <div className="title">Họ và tên :</div>
              <div className="content">{`${order.userInfomationPayment.firstName} ${order.userInfomationPayment.lastName}`}</div>
            </div>
            <div className="wrap-content col">
              <div className="title">Số điện thoại :</div>
              <div className="content">{order.userInfomationPayment.phoneNumber}</div>
            </div>
            <div className="wrap-content col">
              <div className="title">Email :</div>
              <div className="content">{order.userInfomationPayment.email}</div>
            </div>
          </div>
          <div className=" w-50">
            <div className="wrap-content col">
              <div className="title">Tỉnh :</div>
              <div className="content">{order.userInfomationPayment.city}</div>
            </div>
            <div className="wrap-content col">
              <div className="title">Quận :</div>
              <div className="content">{order.userInfomationPayment.district}</div>
            </div>
            <div className="wrap-content col">
              <div className="title">Địa chỉ:</div>
              <div className="content">{order.userInfomationPayment.address}</div>
            </div>
          </div>
        </div>
        <h3 className="mt-1">Chi tiết các khóa học</h3>
        <div className="row mt-1">
          {order.orderDetails &&
            order.orderDetails.map((value, id) => <OrderCourseCard key={id} data={value} />)}
          <div className="total-price">
            <NumberFormat
              value={Math.ceil(order.orderDetails.reduce((prev, cur) => prev + cur.price, 0))}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            đ
          </div>
        </div>
      </div>
    </OrderCardWrapper>
  );
};

export default OrderCard;

const OrderCardWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  .wrap-code {
    margin-left: auto;

    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      margin-left: 0 !important;
      justify-content: start;
    }
  }

  .wrap-content {
    margin-bottom: 10px;

    .title {
      font-weight: 700;
      font-size: 14px !important;
      margin-right: 10px;
    }

    .content {
      color: #495057;
      font-size: 14px !important;

      @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
        overflow-x: hidden !important ;
      }
    }
  }
  .wrap-date-active {
    margin-left: 5px;
    border: 1px solid #c471ed;
    padding: 5px 10px;
    width: fit-content;
    height: fit-content;
    color: #c471ed;
    border-radius: 0.28571429rem;
    margin-bottom: 10px;
    font-size: 0.8rem;

    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      margin-left: 0 !important;
      width: 90% !important;
    }
  }

  .wrap-order-by {
    margin-left: 5px;
    border: 1px solid #f64f59;
    padding: 5px 10px;
    width: fit-content;
    height: fit-content;
    color: #f64f59;
    border-radius: 0.28571429rem;
    margin-bottom: 10px;
    font-size: 0.8rem;

    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      margin-left: 0 !important;
      width: 90% !important;
    }
  }

  .code-active {
    border-radius: 5px;
    background-color: white;
    color: #03e05c;
    border: 1px solid #03e05c;
    height: fit-content;
    padding: 5px 10px;
    margin-left: 5px;

    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      margin-left: 0 !important;
      margin-bottom: 10px !important;
    }
  }

  .activate-code {
    margin-left: auto;
    margin-bottom: 5px;

    input {
      border-radius: 5px;
      border: 0px;
      border: 1px solid #63636333;
      padding: 0px 7px;

      @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
        padding: 7px !important;
        width: 70%;
      }
    }

    .btn-delete {
      background-color: #f04c4c;
      text-transform: none !important;
    }

    button {
      margin-left: 5px;
      padding: 0px 5px;
      color: white;
      background-color: #21ba45;
      text-transform: none !important;

      @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
        font-size: 13px !important;
      }
    }
  }

  .wrap-date {
    border: 1px solid #12c2e9;
    padding: 5px 10px;
    width: fit-content;
    height: fit-content;
    color: #12c2e9;
    border-radius: 0.28571429rem;
    margin-bottom: 10px;
    font-size: 0.8rem;

    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      width: 90% !important;
    }
  }

  .detail {
    background-color: white;
    border-radius: 0.28571429rem;
    padding: 1rem !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    & > .col {
      @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
        flex-direction: column !important;
        .ml-auto {
          margin-left: 0 !important;
        }

        .w-50 {
          width: 100% !important ;
        }
      }
    }

    .col {
      h3 {
        margin-bottom: 10px;
      }
    }

    .wrap-detail-date {
      margin-bottom: 10px !important;
    }
  }

  .order-cou {
    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      &.col {
        flex-direction: column !important;
      }
    }
    .wrap-img {
      width: 20%;
      overflow: hidden;

      @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
        width: 100% !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .wrap-des {
      width: 60%;
      padding: 0px 14px;

      @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
        width: 100% !important;
        padding: 0 !important;
      }
      .name {
        font-weight: 700;
      }

      .des {
        font-size: 12px;
        color: #686f7a;
      }
    }

    .wrap-price {
      width: 20%;
      color: #f04c4c;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      margin-left: auto;
      @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
        width: 100%;
        justify-content: start;
      }
    }
  }

  .total-price {
    margin-left: auto;
    color: #f04c4c;
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;

    @media (max-width: ${({ theme }) => theme.responsive.tabletMid}) {
      font-size: 18px;
    }

    &::before {
      content: "Tổng cộng :";
      color: #6b6b69;
      margin-right: 5px;
    }
  }
`;

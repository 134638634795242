import LoadFb from "app/common/loading/LoadFb";
import LoadOpacity from "app/common/loading/LoadOpacity";
import { useAppSelector } from "app/store/hooks";
import { selectCourseDetail } from "features/course/courseSlice";
import React from "react";
import "./styles.scss";

interface IProps {
  load?: boolean;
}

const CardTitle: React.FC<IProps> = ({ load }) => {
  const couseDetail = useAppSelector(selectCourseDetail);

  if (load)
    return (
      <div className="title-loading">
        <LoadFb width="60%" height="50px" radius="5px" />
        <LoadOpacity width="87%" height="30px" radius="5px" />
      </div>
    );

  return (
    <div className="card-title row">
      <h1>{couseDetail?.name}</h1>
      <p>{couseDetail?.title}</p>
    </div>
  );
};

export default CardTitle;

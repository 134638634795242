import LoadCircle from "app/common/loading/LoadCircle";
import LoadFb from "app/common/loading/LoadFb";
import LoadOpacity from "app/common/loading/LoadOpacity";
import React, { useEffect, useState } from "react";
import js from "app/assets/js.jpg";
import "./styles.scss";
import { VscThreeBars } from "react-icons/vsc";
import { AiFillPlayCircle } from "react-icons/ai";
import { BiTimeFive, BiInfinite } from "react-icons/bi";
import { IoPhonePortraitOutline, IoDocumentOutline } from "react-icons/io5";
import { FaPencilAlt } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { selectCourseDetail, selectIsLoadingDetail } from "features/course/courseSlice";
import NumberFormat from "react-number-format";
import { insertCourseToCart } from "features/cart/cartSlice";
import { selectIsLoaded, selectUserId } from "features/account/userProfileSlice";
import paymentAPI from "app/api/services/order.service";
import { Link } from "react-router-dom";
import { history } from "index";

const CardDescriptionVideo: React.FC = () => {
  const load = useAppSelector(selectIsLoadingDetail);
  const courseDetail = useAppSelector(selectCourseDetail);
  const userId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();
  const [isBuy, setisBuy] = useState(false);
  const isUserloaded = useAppSelector(selectIsLoaded);
  console.log(isUserloaded);
  

  useEffect(() => {
    if (userId && courseDetail?.id) {
      paymentAPI.checkUserBuyCourse(userId, courseDetail.id).then((res) => {
        setisBuy(true);
      });
    }
  }, [userId, courseDetail?.id]);

  if (load)
    return (
      <div className="card-frame loading">
        <div className="wrap-img d-cen">
          <LoadCircle />
        </div>

        <LoadFb width="45%" />

        <div className="row">
          <LoadOpacity />
          <LoadOpacity />
          <LoadOpacity />
          <LoadOpacity />
          <LoadOpacity />
        </div>
      </div>
    );

  const createInfoTile = () => {
    let content: number[] = [];
    if (courseDetail) {
      if (courseDetail.sections.length > 0) {
        content.push(courseDetail.sections.length);

        let countLeatures = 0;
        courseDetail.sections.forEach((sc) => {
          sc.lectures.forEach((lt) => {
            countLeatures += 1;
          });
        });

        content.push(countLeatures);
      }
    } else {
      return [0, 0];
    }
    return content;
  };

  const [total, countLeatures] = createInfoTile();

  const handleInsertToCart = () => {
    if (courseDetail) {
      dispatch(
        insertCourseToCart({
          id: courseDetail.id,
          name: courseDetail.name,
          price: courseDetail.price,
          percent: courseDetail.percent,
          conditionRequirement: courseDetail.conditionRequirement,
          createDate: courseDetail.createDate,
          customerSegment: courseDetail.customerSegment,
          isSale: courseDetail.isSale,
          mainContent: courseDetail.mainContent,
          photo: courseDetail.photo,
          photoId: courseDetail.photoId,
          title: courseDetail.title,
        })
      );
    }
  };

  const handleBuyNow = () => {
    handleInsertToCart();
    history.push("/giohang");
  };

  return (
    <div className="p-0 w-100 mt-1 row">
      <div className="card-des-video-ct">
        <div className="wrap-img">
          <img src={courseDetail?.photo || js} alt="logo" />
        </div>
        <p>Khóa học bao gồm</p>

        <div className="content col">
          <div className="row w-50">
            <div className="sub-con col">
              <div className="col">
                <VscThreeBars />
              </div>
              <div className="des">Chuyên mục : {total}</div>
            </div>
            <div className="sub-con col">
              <div className="col">
                <AiFillPlayCircle />
              </div>
              <div className="des">Bài học: {countLeatures}</div>
            </div>
            <div className="sub-con col">
              <div className="col">
                <BiTimeFive />
              </div>
              <div className="des">Thời lượng </div>
            </div>
            <div className="sub-con col">
              <div className="col">
                <IoPhonePortraitOutline />
              </div>
              <div className="des">Học trên mọi thiết bị có kết nối internet</div>
            </div>
          </div>

          <div className="row w-50">
            <div className="sub-con col">
              <div className="col">
                <FaPencilAlt />
              </div>
              <div className="des">Bài tập</div>
            </div>
            <div className="sub-con col">
              <div className="col">
                <BiInfinite />
              </div>
              <div className="des">Học trọn đời</div>
            </div>
            <div className="sub-con col">
              <div className="col">
                <IoDocumentOutline />
              </div>
              <div className="des">Tài liệu</div>
            </div>
          </div>
        </div>

        <div className="bar-br"></div>

        {courseDetail && (
          <div className="des-price col">
            <div className="price">
              <NumberFormat
                value={Math.ceil(courseDetail.price * (1 - courseDetail.percent))}
                displayType={"text"}
                thousandSeparator={true}
              />
              đ
            </div>
            {courseDetail.percent !== 0 && (
              <div className="old-price">
                <NumberFormat
                  value={Math.ceil(courseDetail?.price)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </div>
            )}
            {courseDetail.percent !== 0 && (
              <div className="percent">-{Math.ceil(courseDetail.percent * 100)}%</div>
            )}
          </div>
        )}

        {courseDetail && Math.ceil(courseDetail.price * (1 - courseDetail.percent)) === 0 ? (
          <div className="btn-grp row">
            {!isUserloaded ? (
              <Link to="/taikhoan/dangnhap" className="btn-buy d-f center btn-login-study">
                Đăng nhập để học ngay
              </Link>
            ) : (
              <Link className="btn-buy d-f center btn-study-now" to={`/khoahoc/xemkhoahoc/${courseDetail?.id}`}>
                Học Ngay
              </Link>
            )}
          </div>
        ) : (
          <div className="btn-grp row">
            {!isBuy ? (
              <>
                <div className="btn-buy d-f center" onClick={(e) => handleBuyNow()}>
                  Mua ngay
                </div>
                <div className="btn-add-cart d-f center" onClick={(e) => handleInsertToCart()}>
                  Thêm vào giỏ hàng
                </div>
              </>
            ) : (
              <Link
                className="btn-add-cart d-f center"
                to={`/khoahoc/xemkhoahoc/${courseDetail?.id}`}
              >
                Học ngay
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CardDescriptionVideo);

import ImageRow from "app/common/components/image-row";
import PStep from "app/common/components/pstep";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container } from "styles/components/div.styled";
import rg from "../assets/regist/rg.jpg";
import rgs from "../assets/regist/rgs.png";

const RegistSP = () => {
  return (
    <RegistSPContainer>
      <Container>
        <h1>Hướng dẫn đăng ký tài khoản</h1>
        <p>
          Xin chào các bạn. Chúng mình đến từ TuHoc3DOnline team. Sau đây là hướng dẫn đăng ký tài
          khoản theo các bước
        </p>
        <PStep className="mt-1" step="1">
          Các bạn truy cập vào link này :{" "}
          <Link target="_blank" rel="noopener noreferrer" className="link" to="/taikhoan/dangky">
            Đăng ký
          </Link>
        </PStep>
        <PStep className="mt-1" step="2">
          Sau đó các bạn điền đầy đủ các thông tin cần thiết và click vào nút đăng ký
          <ImageRow
            src={rg}
            des="Lưu ý : Mật khẩu phải bao gồm ít nhất 1 kí tự a-z , A-Z, 0-9 và kí tự đặc biệt"
          />
        </PStep>
        <PStep className="mt-1" step="3">
          Khi các bạn đăng ký thành công hệ thống sẽ hiện lên giao diện trang cá nhân như hình bên
          dưới
          <ImageRow src={rgs} />
        </PStep>
      </Container>
    </RegistSPContainer>
  );
};

const RegistSPContainer = styled.div`
  padding: 0 8%;
  .link {
    font-weight: 900;
  }
`;

export default RegistSP;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAccountLogin, IAccountRegister } from "app/@types/account";
import { IProfileUser } from "app/api/responses/user";
import userAPI from "app/api/services/user.service";
import { removeToken, saveToken } from "app/helper/tokenManager";
import { showError, showSuccess } from "app/notification";
import { AppDispatch, AppThunk, RootState } from "app/store";
import { history } from "index";
import { IAccountState } from "./@types";

const initialState: IAccountState = {
  appLoaded: false,
  isLoaded: false,
};

export const userLogin =
  (data: IAccountLogin): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const res = await userAPI.login(data);
      dispatch(
        setProfileUser({
          username: res.data.username,
          gender: res.data.gender,
          email: res.data.email,
          phoneNumber: res.data.phoneNumber,
          userId: res.data.userId,
        })
      );
      saveToken({
        accessToken: res.data.token,
        refreshToken: res.data.refreshToken,
      });
      history.push("/taikhoan/trangcanhan");
    } catch (err) {
      showError(err.error);
    }
  };

export const userRegisterAccount =
  (data: IAccountRegister): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const res = await userAPI.registerAccount(data);
      dispatch(
        setProfileUser({
          username: res.data.username,
          gender: res.data.gender,
          email: res.data.email,
          phoneNumber: res.data.phoneNumber,
          userId: res.data.userId,
        })
      );
      saveToken({
        accessToken: res.data.token,
        refreshToken: res.data.refreshToken,
      });
      history.push("/taikhoan/trangcanhan");
    } catch (err) {
      showError(err.error);
    }
  };

export const userUpdateProfile =
  (data: IAccountRegister): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const res = await userAPI.updateProfile(data);
      showSuccess("Cập nhật thành công");
      dispatch(
        setProfileUser({
          username: res.data.username,
          gender: res.data.gender,
          email: res.data.email,
          phoneNumber: res.data.phoneNumber,
          userId: "",
        })
      );
    } catch (err) {
      showError(err.error);
    }
  };

export const getCurrentUser = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const res = await userAPI.getCurrent();
    dispatch(setProfileUser(res.data));
  } catch (err) {
    dispatch(setAppLoad(true));
  }
};

const cartSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProfileUser(state, { payload }: PayloadAction<IProfileUser>) {
      if (!state.user) state.user = payload;
      else {
        const id = state.user.userId;
        state.user = payload;
        state.user.userId = id;
      }
      state.appLoaded = true;
      state.isLoaded = true;
    },
    setAppLoad(state, { payload }: PayloadAction<boolean>) {
      state.appLoaded = payload;
    },
    logout(state) {
      removeToken();
      return initialState;
    },
  },
});

const { actions, reducer } = cartSlice;

export const { setProfileUser, setAppLoad, logout } = actions;

export default reducer;

export const selectUserProfile = (state: RootState) => state.user.user;

export const selectIsLoaded = (state: RootState) => state.user.isLoaded;

export const selectAppLoaded = (state: RootState) => state.user.appLoaded;

export const selectUserId = (state: RootState) => state.user.user?.userId;
